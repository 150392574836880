<template>
    <div
      v-if="showConfirmModal"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50"
    >
      <div
        class="relative w-[420px] sm:w-[500px] bg-[#20122E] py-8 px-6 rounded-2xl text-center flex flex-col items-center overflow-hidden"
      >
        <!-- Close Button -->
        <button
          @click="$emit('closeConfirmModal')"
          class="absolute top-4 right-6 text-white text-4xl font-bold hover:text-gray-300 transition"
        >
          &times;
        </button>
  
        <div class="mt-6">
          <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md">
            Confirm Purchase
          </h2>
          <p class="text-white text-sm px-4 leading-relaxed mt-4">
            Are you sure you want to buy
            <strong class="text-white">{{ productName }}</strong> for
            <strong class="text-yellow-400"
              >{{ requiredPoints }} {{ requiredPoints > 1 ? "points" : "point" }}</strong
            >?
          </p>
  
          <!-- Buttons -->
          <div class="flex w-full gap-4 mt-6">
            <button
              @click="$emit('confirmPurchase')"
              class="w-1/2 px-6 py-2 flex items-center justify-center bg-yellow-500 text-black font-semibold rounded-lg shadow-lg hover:bg-yellow-400 transition"
              :disabled="loading"
            >
              <span v-if="loading" class="animate-spin border-2 border-white border-t-transparent rounded-full w-5 h-5 mr-2"></span>
              <span>{{ loading ? "Processing..." : "Yes, Buy Now" }}</span>
            </button>
            <button
              @click="$emit('closeConfirmModal')"
              class="w-1/2 px-6 py-2 bg-gray-700 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-600 transition"
              :disabled="loading"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showConfirmModal: Boolean,
      productName: String,
      requiredPoints: Number,
      loading: Boolean, // New prop for loading state
    },
  };
  </script>
  