<template>
  <div
    v-if="showOnBoardModal"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50"
  >
    <div
      class="relative w-[420px] sm:w-[500px] bg-[#20122E] py-8 px-6 rounded-2xl text-start flex flex-col items-center overflow-hidden"
    >
      <!-- Close (X) Button -->
      <button
        @click.stop="$emit('closeOnBoardModal')"
        class="absolute top-4 right-6 text-white text-4xl font-bold hover:text-gray-300 transition"
      >
        &times;
      </button>

      <div class="relative z-10 w-full mt-14">
        <div v-if="!isLoggedIn" class="flex flex-col items-center text-center">
          <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md">
            Login Required
          </h2>
          <p class="text-white text-sm px-4 leading-relaxed mt-4">
            You need to be logged in to complete your user information and participate.
          </p>
        </div>

        <div v-else>
          <div v-if="step === 1" class="flex flex-col">
            <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md text-center">
              Verification and Guidelines
            </h2>
            <p class="text-white text-sm px-4 leading-relaxed mt-10">
              Please enter your
              <span class="font-bold text-[#EDB90A]">Twitter, Upgrader,</span> and
              <span class="font-bold text-[#EDB90A]">Rain.gg Usernames</span> accurately.
            </p>
            <p class="text-white text-sm mt-2 px-4 leading-relaxed">
              These are optional but recommended for better tracking.
            </p>

            <p class="text-yellow-400 text-sm mt-4 px-4 text-start font-semibold">
              Ensure your details are correct, as you can only update them after 60 days.
            </p>

            <div class="flex justify-end mt-10 px-4">
              <button
                @click="nextStep"
                class="px-6 py-2 bg-yellow-500 text-black font-semibold rounded-lg shadow-lg hover:bg-yellow-400 transition"
              >
                Next
              </button>
            </div>
          </div>

          <div v-if="step === 2" class="flex flex-col">
            <h2 class="text-2xl drop-shadow-md text-[#EDB90A] font-black mb-10 text-center">
              Enter Your Details
            </h2>
            <input
              v-model="twitterUsername"
              placeholder="Twitter Username (Optional)"
              class="mt-3 w-full px-4 py-2 rounded-lg bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <input
              v-model="upgraderUsername"
              placeholder="Upgrader Username (Optional)"
              class="mt-3 w-full px-4 py-2 rounded-lg bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <input
              v-model="rainGGUsername"
              placeholder="Rain.gg Username (Optional)"
              class="mt-3 w-full px-4 py-2 rounded-lg bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <div class="flex justify-between mt-10 px-4">
              <button
                @click="step = 1"
                class="px-6 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-400 transition"
              >
                Back
              </button>
              <button
                @click="step = 3"
                class="px-6 py-2 bg-yellow-500 hover:bg-yellow-400 text-black font-semibold rounded-lg shadow-lg transition"
              >
                Next
              </button>
            </div>
          </div>

          <div v-if="step === 3" class="flex flex-col items-center text-center">
            <h2 class="text-2xl drop-shadow-md text-[#EDB90A] font-black mb-6">
              Confirm Your Details
            </h2>

            <div class="bg-[#2A1B3D] w-full rounded-lg p-4 text-white text-sm">
              <div class="flex justify-between items-center py-2 border-b border-gray-600">
                <span class="font-bold text-white">Twitter Username:</span>
                <span class="text-[#7F85A3]">{{ twitterUsername || "No changes" }}</span>
              </div>
              <div class="flex justify-between items-center py-2 border-b border-gray-600">
                <span class="font-bold text-white">Upgrader Username:</span>
                <span class="text-[#7F85A3]">{{ upgraderUsername || "No changes" }}</span>
              </div>
              <div class="flex justify-between items-center py-2">
                <span class="font-bold text-white">Rain.gg Username:</span>
                <span class="text-[#7F85A3]">{{ rainGGUsername || "No changes" }}</span>
              </div>
            </div>

            <!-- Error Message Display -->
            <div v-if="errorMessage" class="bg-red-600 text-white text-sm font-semibold mt-4 p-2 rounded-md">
              {{ errorMessage }}
            </div>

            <div class="flex justify-between w-full mt-6 px-4">
              <button
                @click="step = 2"
                class="px-6 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-400 transition"
              >
                Edit
              </button>
              <button
                @click="updateUserData"
                class="px-6 py-2 bg-yellow-500 hover:bg-yellow-400 text-black font-semibold rounded-lg shadow-lg transition"
              >
                Confirm & Submit
              </button>
            </div>
          </div>

          <div v-if="step === 4" class="flex flex-col items-center text-center">
            <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md">
              Submission Successful!
            </h2>
            <p class="text-white text-sm px-4 leading-relaxed mt-4">
              Your details have been successfully submitted. Thank you!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    showOnBoardModal: Boolean,
  },
  data() {
    return {
      step: 1,
      twitterUsername: "",
      upgraderUsername: "",
      rainGGUsername: "",
      isLoggedIn: !!localStorage.getItem("accessToken"),
      errorMessage: "", // Error handling state
    };
  },
  methods: {
    nextStep() {
      this.step = 2;
    },
    async updateUserData() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.errorMessage = ""; // Clear previous errors

      if (!this.isLoggedIn) {
        return;
      }

      const userData = {};
      if (this.twitterUsername) userData.twitter = this.twitterUsername;
      if (this.upgraderUsername) userData.upgrader = this.upgraderUsername;
      if (this.rainGGUsername) userData.raingg = this.rainGGUsername;

      if (Object.keys(userData).length === 0) {
        this.errorMessage = "No changes were made.";
        return;
      }

      try {
        const response = await axios.put(`${baseUrl}/api/user`, userData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        if (response.data.status === 200) {
          this.step = 4;
          setTimeout(() => {
            this.$emit("closeOnBoardModal");
            this.$emit("refreshUserData");
          }, 3000);
        }
      } catch (error) {
        this.errorMessage = "Error updating details. Please try again.";
      }
    },
  },
};
</script>
