<template>
  <div
    class="relative h-full lg:h-full pb-10 overflow-hidden bg-gradient-to-b from-[#0A0D1D] to-[#0A0D1D]"
  >
    <!-- Overlays -->
    <img
      src="@/assets/overlay-1.webp"
      class="hidden lg:flex absolute top-[160px] -left-[160px] lg:-left-[200px] xl:-left-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 1"
    />
    <img
      src="@/assets/overlay-2.webp"
      class="hidden lg:flex absolute top-[500px] right-[260px] lg:-right-[220px] xl:-right-[100px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 2"
    />
    <img
      src="@/assets/overlay-3.webp"
      class="hidden lg:flex absolute top-[1040px] left-[260px] lg:-left-[150px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 3"
    />
    <img
      src="@/assets/overlay-4.webp"
      class="hidden lg:flex absolute top-[1320px] lg:-right-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 4"
    />

    <section
      id="rewards"
      class="px-4 sm:px-6 py-16 lg:px-16 2xl:px-56 pt-[120px] lg:pt-[120px]"
    >
      <div class="flex flex-col justify-center items-center">
        <button
          v-motion-pop-visible
          @click="goBack"
          class="text-white text-lg sm:text-sm md:text-xl font-bold flex items-start w-full max-w-6xl"
        >
          <svg
            class="w-7 h-7 mr-2"
            fill="none"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
          Back
        </button>

        <!-- Guess the Balance Section -->
        <div
          v-motion-pop-visible
          class="bg-[#20122E] px-4 py-4 w-full max-w-6xl rounded-lg flex justify-center items-center border-2 border-[#411D35] mt-6"
        >
          <img src="../assets/lightbulb.webp" alt="Bulb Icon" class="w-8 h-8 mr-2" />
          <span class="text-white font-semibold text-lg lg:text-2xl">
            Guess The Balance # {{ sessionNumber ? sessionNumber : "" }}</span
          >
        </div>

        <!-- Cards Section -->
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full max-w-6xl mt-6"
        >
          <!-- Card 1 -->
          <div
            v-motion-pop-visible
            class="bg-[#20122E] p-6 rounded-lg text-center lg:text-start"
          >
            <span v-if="loading || sessionDetails === null" class="loader"></span>
            <span v-else class="text-3xl font-bold text-white"
              >${{ animatedHighestBet }}</span
            >

            <p class="text-sm opacity-75 text-[#7F85A3] mt-2">Highest Bet</p>
          </div>

          <!-- Card 2 -->
          <div
            v-motion-pop-visible
            class="bg-[#20122E] p-6 rounded-lg text-center lg:text-start"
          >
            <span v-if="loading || sessionDetails === null" class="loader"></span>
            <span v-else class="text-3xl font-bold text-white"
              >${{ animatedHighestWin }}</span
            >

            <p class="text-sm opacity-75 text-[#7F85A3] mt-2">Highest Win</p>
          </div>

          <!-- Card 3 -->
          <div
            v-motion-pop-visible
            class="bg-[#20122E] p-6 rounded-lg text-center lg:text-start"
          >
            <span v-if="loading || sessionDetails === null" class="loader"></span>
            <span v-else class="text-3xl font-bold text-white"
              >x {{ animatedHighestMultiplier }}</span
            >

            <p class="text-sm opacity-75 text-[#7F85A3] mt-2">Highest Multiplier</p>
          </div>

          <!-- Card 4 -->
          <div
            v-motion-pop-visible
            class="bg-[#20122E] p-6 rounded-lg text-center lg:text-start"
          >
            <span v-if="loading || sessionDetails === null" class="loader"></span>
            <span
              v-else
              :class="[
                'text-3xl font-black',
                isNaN(Number((animatedTotalProfit || '').replace(/,/g, ''))) ||
                Number((animatedTotalProfit || '').replace(/,/g, '')) < 0
                  ? 'text-red-500 red-glow'
                  : 'text-[#39FF14] green-glow',
              ]"
            >
              ${{ animatedTotalProfit || "0.00" }}
            </span>

            <p class="text-sm opacity-75 text-[#7F85A3] mt-2">Total Profit</p>
          </div>
        </div>

        <div
          v-if="sessionDetails && sessionDetails !== 'ongoing' && countdownActive"
          class="flex flex-col justify-center items-center mt-16 mb-10"
        >
          <h2 class="text-3xl font-bold text-white font-montserrat">Session Countdown</h2>
          <div class="relative flex">
            <h4
              class="text-[#EEAF0E] gold-glow text-[80px] xl:text-[140px] font-lalezar font-black text-center"
            >
              {{ countdownTime }}
            </h4>
          </div>
        </div>

        <!-- Title -->
        <div
          v-motion-pop-visible
          class="w-full max-w-6xl flex justify-start items-center mt-10"
        >
          <h2
            v-motion-pop-visible
            class="text-white font-montserrat font-bold text-lg lg:text-xl text-center mb-4 flex items-center justify-start gap-2 lg:ml-8"
          >
            <img src="../assets/trophy.webp" alt="Arrow" class="w-6 h-6" />
            Winner
          </h2>
        </div>

        <div v-if="loading || sessionDetails === null" class="flex justify-center">
          <span class="loader"></span>
        </div>

        <template v-else>
          <!-- Winners Section -->
          <div class="grid grid-cols-1 gap-6 w-full max-w-6xl mt-4">
            <!-- First Place -->

            <div v-motion-pop-visible class="bg-[#20122E] p-6 rounded-lg text-center">
              <img
                src="../assets/first.webp"
                alt="Gold Crown"
                class="w-16 h-16 mx-auto mb-2"
              />
              <span class="text-white font-black text-xl"> {{ animatedUsername }}</span>
              <p class="text-lg text-[#7F85A3] mt-2">${{ animatedGuess }}</p>
            </div>
          </div>

          <!-- Add Guess Button -->
          <div class="flex justify-end items-center w-full max-w-6xl">
            <button
              @click="openModal"
              :disabled="
                !isLoggedIn ||
                hasGuessed ||
                sessionDetails?.status === 'done' ||
                sessionDetails?.status === 'ongoing' ||
                hasRoobet
              "
              class="bg-[#20122E] text-white font-medium px-4 py-2 rounded-lg w-60 flex items-center justify-between border-2 border-solid border-[#411D35] shadow-md mt-[40px] lg:mt-[100px]"
              :class="{
                'opacity-50 cursor-not-allowed':
                  !isLoggedIn ||
                  hasGuessed ||
                  sessionDetails?.status === 'done' ||
                  sessionDetails?.status === 'ongoing' ||
                  hasRoobet,
              }"
            >
              Add Guess
              <img
                src="../assets/game.png"
                alt="Game Icon"
                class="w-7 h-5 transition-transform duration-300"
              />
            </button>
          </div>

          <!-- GTB Table -->
          <div class="w-full max-w-6xl mt-6">
            <div class="bg-[#17112A] rounded-lg overflow-hidden">
              <div v-if="loading" class="flex justify-center items-center p-4">
                <span class="loader"></span>
              </div>

              <div v-else class="overflow-x-auto">
                <table class="w-full text-left text-white font-montserrat">
                  <thead class="bg-[#17112A]">
                    <tr>
                      <th class="px-6 py-3 text-[#7F85A3]">ID</th>
                      <th class="px-6 py-3 text-[#7F85A3]">Slot</th>
                      <th class="px-6 py-3 text-[#7F85A3]">Bet</th>
                      <th class="px-6 py-3 text-[#7F85A3]">Multiplier</th>
                      <th class="px-6 py-3 text-[#7F85A3]">Win</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(slot, index) in slotData"
                      :key="slot.id"
                      :class="{
                        'bg-[#20122E]': index % 2 === 0, // Even rows
                        'bg-[#0A0D1D]': index % 2 !== 0, // Odd rows
                      }"
                    >
                      <td class="px-6 py-3 text-[#7F85A3]">#{{ index + 1 }}</td>
                      <td class="px-6 py-3 text-[#7F85A3]">{{ slot.slotTitle }}</td>
                      <td class="px-6 py-3 text-[#7F85A3]">
                        ${{ slot.bonusBuyCost.toLocaleString() }}
                      </td>
                      <td class="px-6 py-3 text-[#7F85A3]">
                        x{{ (slot.bonusBuyPayout / slot.bonusBuyCost).toFixed(2) }}
                      </td>

                      <td
                        class="px-6 py-3"
                        :class="
                          slot.bonusBuyPayout >= 0
                            ? 'text-[#39FF14] font-black green-glow'
                            : 'text-red-500 font-black red-glow'
                        "
                      >
                        ${{ Math.abs(slot.bonusBuyPayout).toLocaleString() }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>

        <!-- Pagination -->
        <div class="w-full max-w-6xl mt-10 border-2 border-[#411D35] rounded-lg">
          <div
            v-motion-pop-visible
            class="bg-[#20122E] rounded-lg p-4 flex items-center justify-between select-none"
          >
            <!-- Left Section: Page Info -->
            <div class="flex items-center gap-2">
              <img
                src="@/assets/search.webp"
                alt="Search"
                class="w-10 h-10 sm:w-14 sm:h-14 object-contain"
              />
              <span class="text-white font-bold text-base sm:text-lg font-montserrat">
                {{ startItem }} - {{ endItem }} of {{ totalItems }} items
              </span>
            </div>

            <!-- Pagination Controls -->
            <div class="flex items-center gap-2">
              <!-- Previous Button -->
              <button
                class="p-2 bg-[#5A2E80] rounded-md disabled:opacity-50"
                :disabled="currentPage === 1"
                @click="prevPage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>

              <!-- Next Button -->
              <button
                class="p-2 bg-[#5A2E80] rounded-md disabled:opacity-50"
                :disabled="currentPage === totalPages"
                @click="nextPage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center w-full max-w-6xl">
          <h1 class="font-montserrat text-4xl font-black text-white mb-6 mt-10">
            My Guess
          </h1>
        </div>
        <section class="w-full pb-[40px] md:px-0">
          <div class="w-full flex flex-col items-center justify-center z-10 mt-6">
            <div
              class="w-full max-w-6xl bg-[#20122E] rounded-3xl p-4 shadow-lg border-solid border-2 border-[#411D35] backdrop-filter backdrop-blur-[7px]"
            >
              <div v-if="loading" class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
              <div v-else class="overflow-x-auto">
                <table
                  class="w-full text-[#9CA3AF] font-bold border-none border-spacing-y-2"
                >
                  <thead>
                    <tr class="text-left border-0 text-white">
                      <th class="p-3 text-[#7F85A3] rounded-l-lg">Username</th>
                      <th class="p-3 text-[#7F85A3]">Guess</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="guess in filteredGuesses"
                      :key="guess._id"
                      class="bg-[#20122E] text-start rounded-xl"
                    >
                      <td class="p-3 text-[#7F85A3]">{{ guess.user.username }}</td>
                      <td class="p-3 text-[#7F85A3]">
                        ${{ guess.guess ? guess.guess.toLocaleString() : "0" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AddGuessModal
        v-if="showModal"
        ref="addGuessModal"
        @guessSubmitted="handleGuessSubmitted"
        @close="closeModal"
      />
      <RedirectModal
        :showRedirectModal="showRedirectModal"
        @closeRedirectModal="closeRedirectModal"
      />
    </section>
  </div>
</template>

<script>
// import axios from 'axios';
import AddGuessModal from "@/components/AddGuessModal.vue";
import RedirectModal from "@/components/RedirectModal.vue";
import axios from "axios";
import moment from "moment-timezone";
// import { watch } from 'vue';
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
  name: "GuessTheBalanceDetailsPage",
  components: {
    AddGuessModal,
    RedirectModal,
  },
  data() {
    return {
      leaderboardData: [],
      slotData: [],
      slotCount: null,
      sessionDetails: null,
      sessionNumber: null,
      userGuesses: [],
      slotSummary: {
        highestBet: null,
        highestWin: null,
        totalProfit: null,
      },

      animatedHighestBet: null,
      animatedHighestWin: null,
      animatedHighestMultiplier: null,
      animatedTotalProfit: null,
      animatedSlotCount: null,
      animatedUsername: "",
      animatedGuess: null,
      animatedPrize: null,
      animatedPrizeMobile: null,
      animatedDate: "",
      dateAnimationInterval: null,
      isLoggedIn: false,

      showGuessModal: false,

      loading: false,
      showDisclaimer: false,
      showModal: false,
      showRedirectModal: false,
      hasGuessed: false,
      hasRoobet: false,

      countdownTime: "00:00",
      countdownActive: true,

      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    highestMultiplier() {
      if (!this.slotData || this.slotData.length === 0) return "0.00"; // Handle empty data

      return Math.max(
        ...this.slotData.map((slot) =>
          slot.bonusBuyCost > 0 ? slot.bonusBuyPayout / slot.bonusBuyCost : 0
        )
      ).toFixed(2);
    },
    totalItems() {
      return this.slotData.length;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    startItem() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    endItem() {
      return Math.min(this.startItem + this.itemsPerPage - 1, this.totalItems);
    },
    paginatedSlots() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.slotData.slice(start, start + this.itemsPerPage);
    },
    formattedCountdownTime() {
      if (!this.countdownTime) return "00:00";
      return this.countdownTime;
    },
    filteredGuesses() {
      return this.userGuesses.filter((guess) => guess.gtb._id === this.$route.params.id);
    },
    formattedMinutes() {
      return this.timer ? String(this.timer.minutes.value).padStart(2, "0") : "00";
    },
    formattedSeconds() {
      return this.timer ? String(this.timer.seconds.value).padStart(2, "0") : "00";
    },
  },
  watch: {
    sessionDetails: {
      handler() {
        this.initializeTimer();
      },
      immediate: true,
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        await Promise.all([
          this.fetchGTBSessions(),
          this.fetchSlotStatus(),
          this.fetchUserGuesses(),
          this.checkUserAttributes(),
        ]);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchGTBSessions() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      try {
        const response = await axios.get(`${baseUrl}/api/guess-the-balance`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });

        if (response.data && Array.isArray(response.data.gtbs)) {
          // Store all GTB sessions
          this.leaderboardData = response.data.gtbs;

          // Find the session by ID
          const sessionId = this.$route.params.id;
          const matchedSession = this.leaderboardData.find(
            (gtb) => gtb._id === sessionId
          );

          if (matchedSession) {
            this.sessionDetails = {
              id: matchedSession._id,
              date: matchedSession.createdAt
                ? new Date(matchedSession.createdAt).toLocaleDateString("en-US")
                : "N/A",
              status: matchedSession.status || "N/A",
              startingBalance: matchedSession.startingBalance ?? 0,
              biggestWin: matchedSession.biggestWin ?? 0,
              endingBalance: matchedSession.endingBalance ?? 0,
              prize: matchedSession.prize ?? 0,
              profitLoss:
                matchedSession.endingBalance !== null &&
                matchedSession.startingBalance !== null
                  ? matchedSession.endingBalance - matchedSession.startingBalance
                  : "TBD",
              timerTimeStarted: matchedSession.timerTimeStarted || "N/A",
              timerDuration: matchedSession.timerDuration ?? 0,
              updatedAt: matchedSession.updatedAt || "N/A",
              winner: matchedSession.winner
                ? {
                    id: matchedSession.winner._id || "N/A",
                    guess: matchedSession.winner.guess ?? "N/A",
                    user: matchedSession.winner.user
                      ? {
                          id: matchedSession.winner.user._id || "N/A",
                          username: matchedSession.winner.user.username || "N/A",
                          displayName: matchedSession.winner.user.displayName || "N/A",
                          email: matchedSession.winner.user.email || "N/A",
                          profileImage: matchedSession.winner.user.profileImage || null,
                          discordId: matchedSession.winner.user.discordId || "N/A",
                          status: matchedSession.winner.user.status || "N/A",
                          createdAt: matchedSession.winner.user.createdAt || "N/A",
                          updatedAt: matchedSession.winner.user.updatedAt || "N/A",
                          kick: matchedSession.winner.user.kick || "N/A",
                        }
                      : null,
                  }
                : null,
            };

            // console.log(this.sessionDetails, "status shibal");

            // Find the session position in the sorted list
            const sessionIndex = this.leaderboardData.findIndex(
              (gtb) => gtb._id === sessionId
            );
            this.sessionNumber = sessionIndex !== -1 ? sessionIndex + 1 : null;

            // console.log("Session Number:", this.sessionNumber);
          } else {
            console.log("Session not found");
          }
        }
      } catch (error) {
        console.error("Error fetching GTB sessions", error);
      }
    },
    async fetchSlotStatus() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      const sessionId = this.$route.params.id;

      try {
        const response = await axios.get(`${baseUrl}/api/slot/status/${sessionId}`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });

        if (response.data && Array.isArray(response.data.slots)) {
          this.slotData = response.data.slots.map((slot) => ({
            slotTitle: slot.slotTitle,
            status: slot.status,
            bonusBuyCost: slot.bonusBuyCost,
            bonusBuyPayout: slot.bonusBuyPayout,
            profit: slot.profit,
          }));

          this.slotSummary = {
            highestBet: response.data.summary.highestBet,
            highestWin: response.data.summary.highestWin,
            totalProfit: response.data.summary.totalProfit,
          };

          this.slotCount = response.data.gtb?.slotCount || 0;
          // console.log(this.slotData);
        } else {
          console.log("No slots found or invalid response");
        }
      } catch (error) {
        console.error("Error fetching slot status", error);
      }
    },
    async fetchUserGuesses() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        console.error("Access token not found");
        return;
      }

      try {
        const response = await axios.get(
          `${baseUrl}/api/guess-the-balance/guesses/all-guesses`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.status === 200 && Array.isArray(response.data.gtbGuesses)) {
          this.userGuesses = response.data.gtbGuesses;
          // console.log("User guesses fetched successfully", this.userGuesses);

          const sessionId = this.$route.params.id;
          this.hasGuessed = this.userGuesses.some((guess) => guess.gtb._id === sessionId);
        }
      } catch (error) {
        console.error("Error fetching user guesses", error);
      }
    },
    async checkUserAttributes() {
      const token = localStorage.getItem("accessToken");

      if (token) {
        axios
          .get(process.env.VUE_APP_BASE_API + "/api/user", {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data?.user) {
              const attributes = res.data.user.attributes || {};

              this.hasRoobet =
                process.env.APP_ENV === "production" ? !attributes.roobet : false;
              this.showRedirectModal = !attributes.roobet;

              // Only show the redirect modal if in production
              this.showRedirectModal =
                process.env.APP_ENV === "production" && !attributes.roobet;
            }
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              localStorage.removeItem("vuex");
              localStorage.removeItem("accessToken");
              window.location.reload();
            } else {
              console.log(`Error: ${error.response?.status || error.message}`);
            }
          });
      }
    },
    initializeTimer() {
      if (!this.sessionDetails || !this.sessionDetails.timerTimeStarted) return;

      // Get the end time based on the session start time and duration
      const endTime = moment(this.sessionDetails.timerTimeStarted)
        .utc()
        .add(this.sessionDetails.timerDuration, "minutes")
        .valueOf(); // Convert to timestamp

      this.updateCountdown(endTime); // Initialize countdown

      // Clear any existing interval before setting a new one
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      // Set an interval to update countdown every second
      this.countdownInterval = setInterval(() => {
        this.updateCountdown(endTime);
      }, 1000);
    },
    updateCountdown(endTime) {
      const now = moment().utc().valueOf();
      const remainingTime = endTime - now;

      if (remainingTime <= 0) {
        clearInterval(this.countdownInterval);
        this.countdownTime = "00:00";
        this.countdownActive = false;

        this.refreshData();
      } else {
        const minutes = Math.floor(remainingTime / 60000);
        const seconds = Math.floor((remainingTime % 60000) / 1000);
        this.countdownTime = `${String(minutes).padStart(2, "0")}:${String(
          seconds
        ).padStart(2, "0")}`;
      }
    },
    refreshData() {
      if (!this.countdownActive) return;

      if (this.loading) return;
      this.loading = true;

      Promise.all([
        this.fetchGTBSessions(),
        this.fetchSlotStatus(),
        this.fetchUserGuesses(),
      ]).finally(() => {
        this.loading = false;
      });
    },
    goBack() {
      this.$router.back();
    },
    animateValues() {
      this.animateNumber("animatedHighestBet", this.slotSummary?.highestBet || "");
      this.animateNumber("animatedHighestWin", this.slotSummary?.highestWin || "");
      this.animateNumber("animatedHighestMultiplier", this.highestMultiplier || "");
      this.animateNumber("animatedTotalProfit", this.slotSummary?.totalProfit || "");
      this.animateNumber("animatedGuess", this.sessionDetails?.winner?.guess ?? "");
      this.animateNumber("animatedPrize", this.sessionDetails?.prize ?? "");
      this.animateNumber("animatedPrizeMobile", this.sessionDetails?.prize ?? "");
      this.animateNumber("animatedSlotCount", this.slotCount ?? "");
      this.animateName("animatedUsername", this.sessionDetails?.winner?.user?.username);
      this.animateDate("animatedDate", this.sessionDetails.date);
    },
    animateNumber(variable, targetValue) {
      let startValue = Number(String(this[variable]).replace(/,/g, "")) || 0; // Ensure it's a number

      if (isNaN(targetValue) || targetValue === null) {
        this[variable] = "TBD";
        return;
      }

      targetValue = Number(targetValue);
      const increment = Math.max(1, Math.ceil(Math.abs(targetValue - startValue) / 30));

      const interval = setInterval(() => {
        if (startValue < targetValue) {
          startValue += increment;
          if (startValue > targetValue) startValue = targetValue;
        } else {
          startValue -= increment;
          if (startValue < targetValue) startValue = targetValue;
        }

        // Format number with commas, except for animatedHighestMultiplier
        if (variable === "animatedHighestMultiplier") {
          this[variable] = startValue.toFixed(2);
        } else {
          this[variable] = startValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
        }

        if (startValue === targetValue) {
          clearInterval(interval);
        }
      }, 50);
    },

    animateDate() {
      if (!this.sessionDetails || !this.sessionDetails.date) return;

      const currentYear = new Date().getFullYear(); // Get real-time year
      let currentDate = new Date(currentYear, 0, 1); // January 1st of the current year
      const targetDate = new Date(this.sessionDetails.date);

      this.dateAnimationInterval = setInterval(() => {
        if (currentDate >= targetDate) {
          clearInterval(this.dateAnimationInterval);
          this.animatedDate = targetDate.toLocaleDateString("en-US");
        } else {
          currentDate.setDate(currentDate.getDate() + 2); // Increment 5 days per step
          this.animatedDate = currentDate.toLocaleDateString("en-US");
        }
      }, 30); // Fast animation (updates every 30ms)
    },

    animateName() {
      const realUsername = this.sessionDetails?.winner?.user?.username;

      // If the username is missing, set it to "TBD" and stop the animation
      if (!realUsername) {
        this.animatedUsername = "TBD";
        return;
      }

      let counter = 0;
      const usernameLength = realUsername.length;
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      const generateRandomString = (length) => {
        return Array.from(
          { length },
          () => characters[Math.floor(Math.random() * characters.length)]
        ).join("");
      };

      this.usernameAnimationInterval = setInterval(() => {
        if (counter >= 10) {
          // Stop animation after 1 second (10 * 100ms)
          clearInterval(this.usernameAnimationInterval);
          this.animatedUsername = realUsername;
        } else {
          this.animatedUsername = generateRandomString(usernameLength);
          counter++;
        }
      }, 100);
    },
    getRandomDate() {
      const year = 2024; // Fixed year for consistency
      const month = Math.floor(Math.random() * 12) + 1;
      const day = Math.floor(Math.random() * 28) + 1;
      return `${month}/${day}/${year}`;
    },
    checkLoginStatus() {
      this.isLoggedIn = !!localStorage.getItem("accessToken"); // Convert to boolean
    },
    toggleDisclaimer() {
      this.showDisclaimer = !this.showDisclaimer;
    },
    openModal() {
      const sessionId = this.$route.params.id; // Get session ID from URL
      this.showModal = true;
      this.$nextTick(() => {
        this.$refs.addGuessModal.open(sessionId); // Pass the session ID to the modal
      });
    },
    closeModal() {
      this.showModal = false;
    },
    closeRedirectModal() {
      this.showRedirectModal = false;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    handleGuessSubmitted() {
      // Avoid multiple unnecessary calls
      this.refreshData();
    },
  },

  mounted() {
    setTimeout(async () => {
      this.isReady = true;

      await Promise.all([
        this.fetchGTBSessions(),
        this.fetchSlotStatus(),
        this.fetchUserGuesses(),
        this.checkUserAttributes(),
      ]);

      this.checkLoginStatus();
      this.animateValues();
    }, 1000);
  },
};
</script>

<style scoped>
/* Smooth fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.timer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px; /* Reduced width for mobile */
  height: 90px; /* Reduced height for mobile */
  background-color: #20122e;
  border-radius: 10px;
  border: 2px solid #411d35;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: center;
}

.timer-box h4 {
  font-size: 36px; /* Reduced font size for mobile */
  color: white;
  font-weight: bold;
}

.timer-box h6 {
  font-size: 14px; /* Adjusted for better mobile readability */
  color: white;
  text-transform: uppercase;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .timer-box {
    width: 90px;
    height: 90px;
  }

  .timer-box h4 {
    font-size: 36px;
  }

  .timer-box h6 {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .timer-box {
    width: 60px; /* Further reduced for very small screens */
    height: 60px;
  }

  .timer-box h4 {
    font-size: 20px;
  }

  .timer-box h6 {
    font-size: 10px;
  }
}

/* Ensure the timer boxes wrap correctly */
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px; /* Space between timer boxes */
}

.border-gradient {
  position: relative;
  border-radius: 8px;
}

.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(90deg, #d19e00 0%, #c775df 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gold-glow {
  text-shadow: 0px 0px 3px #eeaf0e, 0px 0px 5px #eeaf0e, 0px 0px 20px #eeaf0e;
}

.green-glow {
  text-shadow: 0px 0px 4px #39ff14;
}

.red-glow {
  text-shadow: 0px 0px 4px #ff0000; /* Red Glow */
}

.loader {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
