<template>
  <div
    class="relative h-full lg:h-full pb-10 overflow-hidden bg-gradient-to-b from-[#0A0D1D] to-[#0A0D1D]"
  >
    <!-- Overlays -->
    <img
      src="@/assets/overlay-1.webp"
      class="hidden lg:flex absolute top-[160px] -left-[160px] lg:-left-[200px] xl:-left-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 1"
    />
    <img
      src="@/assets/overlay-2.webp"
      class="hidden lg:flex absolute top-[500px] right-[260px] lg:-right-[220px] xl:-right-[100px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 2"
    />
    <img
      src="@/assets/overlay-3.webp"
      class="hidden lg:flex absolute top-[1040px] left-[260px] lg:-left-[150px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 3"
    />
    <img
      src="@/assets/overlay-4.webp"
      class="hidden lg:flex absolute top-[1320px] lg:-right-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 4"
    />

    <section
      id="rewards"
      class="px-4 sm:px-6 py-16 lg:px-16 2xl:px-56 pt-[120px] lg:pt-[120px]"
    >
      <div class="flex flex-col items-center">
        <!-- Banner -->
        <div v-motion-pop-visible class="relative w-full max-w-6xl">
          <img
            src="../assets/guess-the-balance-banner.webp"
            alt="Rewards Banner"
            class="w-full object-contain"
          />
        </div>

        <!-- Cards Section -->
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full max-w-6xl"
        >
          <!-- Card 1 -->
          <div
            v-motion-pop-visible
            class="bg-[#20122E] p-6 rounded-lg text-center lg:text-start"
          >
            <h2
              class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px]"
            >
              <span v-if="loading || animatedBonusHunts === null" class="loader"></span>
              <span class="text-white" v-else>{{ animatedBonusHunts }}</span>
            </h2>
            <p class="text-sm opacity-75 text-[#7F85A3] mt-2">Total Bonus Hunts</p>
          </div>

          <!-- Card 2 -->
          <div
            v-motion-pop-visible
            class="bg-[#20122E] p-6 rounded-lg text-center lg:text-start"
          >
            <h2
              class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px]"
            >
              <span v-if="loading || animatedTotalCost === null" class="loader"></span>
              <span class="text-white" v-else>${{ animatedTotalCost }}</span>
            </h2>
            <p class="text-sm opacity-75 text-[#7F85A3] mt-2">Total Cost</p>
          </div>

          <!-- Card 3 -->
          <div
            v-motion-pop-visible
            class="bg-[#20122E] p-6 rounded-lg text-center lg:text-start"
          >
            <h2
              class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px]"
            >
              <span v-if="loading || animatedTotalPayout === null" class="loader"></span>
              <span class="text-white" v-else>${{ animatedTotalPayout }}</span>
            </h2>
            <p class="text-sm opacity-75 text-[#7F85A3] mt-2">Total Payout</p>
          </div>

          <!-- Card 4 -->
          <div
            v-motion-pop-visible
            class="bg-[#20122E] p-6 rounded-lg text-center lg:text-start"
          >
            <h2
              class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px]"
            >
              <span v-if="loading || animatedTotalProfit === null" class="loader"></span>
              <span
                class="text-white"
                :class="
                  Number(animatedTotalProfit.toString().replace(/,/g, '')) >= 0
                    ? 'text-[#39ff14] green-glow'
                    : 'text-[#ff0000] red-glow'
                "
                v-else
              >
                ${{ animatedTotalProfit }}
              </span>
            </h2>
            <p class="text-sm opacity-75 text-[#7F85A3] mt-2">Total Profit</p>
          </div>
        </div>

        <!-- Refresh Button -->
        <div class="w-full flex justify-center items-center max-w-6xl">
          <button
            v-motion-pop-visible
            @click="refreshData"
            class="mt-10 px-4 py-2 bg-[#EEAF0E] w-full max-w-xs text-white font-semibold rounded-lg shadow-md hover:bg-[#D18F0C] flex justify-center items-center gap-2 text-2xl"
          >
            Refresh
          </button>
        </div>
        <!-- Title -->
        <div
          class="w-full max-w-6xl flex justify-center lg:justify-start items-center mt-10"
        >
          <h2
            class="text-white font-montserrat font-bold text-lg lg:text-xl text-center mb-4 flex items-center justify-start gap-2 lg:ml-8"
          >
            <img src="../assets/list-outline.webp" alt="Arrow" class="w-5 h-5" />
            Current Guess the Balance
          </h2>
        </div>

        <!-- GTB Table -->
        <div class="w-full max-w-6xl mt-6">
          <div v-if="loading" class="flex justify-center items-center">
            <span class="loader"></span>
          </div>

          <div
            v-else-if="currentGTBSession"
            class="bg-[#17112A] rounded-lg overflow-x-auto"
          >
            <table class="w-full text-left text-white font-montserrat">
              <thead class="bg-[#17112A]">
                <tr>
                  <th class="px-6 py-3 text-start text-[#7F85A3]">Status</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Date</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Start Balance</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Bonuses</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Biggest Win</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Highest Multiplier</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Profit/Loss</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-left rounded-xl bg-gradient-to-r from-[#D18F0C] via-[#EEAF0E] to-[#D18F0C]"
                >
                  <!-- Streamer -->
                  <td class="p-3 rounded-l-lg text-left">
                    <span
                      class="text-[#20122E] px-4 py-2 rounded-lg text-sm font-montserrat font-black capitalize"
                    >
                      {{ currentGTBSession.status }}
                    </span>
                  </td>

                  <!-- Date -->
                  <td class="p-3 text-[#20122E] text-left">
                    {{ currentGTBSession.date }}
                  </td>

                  <!-- Starting Balance -->
                  <td class="p-3 text-[#20122E] text-left">
                    ${{ currentGTBSession.gtb?.startingBalance ?? "TBD" }}
                  </td>

                  <!-- Bonuses -->
                  <td class="p-3 text-[#20122E] text-left">
                    {{ Math.abs(currentGTBSession.gtb?.bonuses) ?? "TBD" }}
                  </td>

                  <!-- Biggest Win -->
                  <td class="p-3 text-[#20122E] text-left">
                    ${{ currentGTBSession.gtb?.biggestWin ?? "TBD" }}
                  </td>

                  <!-- Highest Bet -->
                  <td class="p-3 text-[#20122E] text-left">
                    x {{ currentGTBSession.gtb?.highestMultiplier ?? "TBD" }}
                  </td>

                  <!-- Profit/Loss -->
                  <td class="p-3 text-left">
                    <span
                      :class="{
                        'text-[#39ff14] font-montserrat font-black':
                          currentGTBSession.totalProfit > 0,
                        'text-[#ff0000] font-montserrat font-black':
                          currentGTBSession.totalProfit < 0,
                        'text-[#20122E]':
                          currentGTBSession.totalProfit === 0 ||
                          currentGTBSession.totalProfit === null,
                      }"
                      class="px-4 py-2 rounded-lg text-[#20122E]"
                    >
                      {{
                        typeof currentGTBSession.totalProfit === "number"
                          ? "$" + currentGTBSession.totalProfit
                          : "TBD"
                      }}
                    </span>
                  </td>

                  <!-- Action -->
                  <td class="p-3 rounded-r-lg text-left">
                    <button
                      @click="$router.push(`/guess-the-balance/${currentGTBSession.id}`)"
                      class="bg-[#20122E] text-white px-4 py-2 rounded-lg shadow-md hover:scale-105 transition-all"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else class="text-white text-center">
            No active Guess the Balance session.
          </div>
        </div>

        <!-- Title -->
        <div
          class="w-full max-w-6xl flex justify-center lg:justify-start items-center mt-10"
        >
          <h2
            class="text-white font-montserrat font-bold text-lg lg:text-xl text-center mb-4 flex items-center justify-start gap-2 lg:ml-8"
          >
            <img src="../assets/list-outline.webp" alt="Arrow" class="w-5 h-5" />
            Previous Guess the Balance
          </h2>
        </div>

        <!-- GTB Table -->
        <div class="w-full max-w-6xl mt-6">
          <div v-if="loading" class="flex justify-center items-center">
            <span class="loader"></span>
          </div>

          <div
            v-else-if="previousGTBSession.length > 0"
            class="bg-[#17112A] rounded-lg overflow-x-auto"
          >
            <table class="w-full text-left text-white font-montserrat">
              <thead class="bg-[#17112A]">
                <tr>
                  <th class="px-6 py-3 text-start text-[#7F85A3]">Status</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Date</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Start Balance</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Bonuses</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Biggest Win</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Highest Multiplier</th>
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Profit/Loss</th>
                  <!-- <th class="px-3 py-3 text-start text-[#7F85A3]">Prize</th> -->
                  <th class="px-3 py-3 text-start text-[#7F85A3]">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(entry, index) in filteredGTBSession"
                  :key="entry.id"
                  :class="{
                    'bg-[#20122E]': index % 2 === 0, // Even rows
                    'bg-[#0A0D1D]': index % 2 !== 0, // Odd rows
                  }"
                  class="text-left rounded-xl"
                >
                  <!-- Streamer -->
                  <td class="p-3 rounded-l-lg text-left">
                    <span
                      class="text-[#EEAF0E] px-4 py-2 rounded-lg text-sm font-montserrat font-black capitalize"
                    >
                      {{ entry.status }}
                    </span>
                  </td>

                  <!-- Date -->
                  <td class="p-3 text-[#7F85A3] text-left">{{ entry.date }}</td>

                  <!-- Starting Balance -->
                  <td class="p-3 text-[#7F85A3] text-left">
                    ${{ entry.startingBalance }}
                  </td>

                  <!-- Bonuses -->
                  <td class="p-3 text-[#7F85A3] text-left">
                    {{ Math.abs(entry.bonuses) }}
                  </td>

                  <!-- Biggest Win -->
                  <td class="p-3 text-[#7F85A3] text-left">
                    ${{ Math.abs(entry.biggestWin) }}
                  </td>

                  <!-- Highest -->
                  <td class="p-3 text-[#7F85A3] text-left">
                    x {{ Math.abs(entry.highestMultiplier) }}
                  </td>

                  <!-- Profit/Loss -->
                  <td class="p-3 text-left">
                    <span
                      :class="{
                        'text-[#39ff14] font-montserrat font-black':
                          entry.status === 'done' &&
                          entry.endingBalance > entry.startingBalance,
                        'text-[#ff0000] font-montserrat font-black':
                          entry.status === 'done' &&
                          entry.endingBalance < entry.startingBalance,
                        'text-[#7F85A3] font-montserrat':
                          entry.status === 'done' &&
                          entry.endingBalance === entry.startingBalance,
                        'text-[#7F85A3]':
                          entry.status !== 'done' || entry.endingBalance === null,
                      }"
                      class="px-4 py-2 rounded-lg"
                    >
                      {{
                        entry.status === "done" && entry.endingBalance !== null
                          ? "$" +
                            (entry.endingBalance - entry.startingBalance).toLocaleString()
                          : "TBD"
                      }}
                    </span>
                  </td>

                  <!-- Prize -->
                  <!-- <td class="p-3 text-[#7F85A3] text-left min-w-[100px] w-[100px]">
                    <span class="bg-[#582B58] text-[#9CA3AF] px-4 py-2 rounded-lg block">
                      ${{ entry.prize }}
                    </span>
                  </td> -->

                  <!-- Action -->
                  <td class="p-3 rounded-r-lg text-left">
                    <button
                      @click="$router.push(`/guess-the-balance/${entry.id}`)"
                      class="bg-[#EEAF0E] text-white px-4 py-2 rounded-lg shadow-md hover:scale-105 transition-all"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else class="text-white text-center">
            No previous Guess the Balance session.
          </div>
        </div>

        <!-- Pagination -->
        <div class="w-full max-w-6xl mt-10 border-2 border-[#411D35] rounded-lg">
          <div
            class="bg-[#20122E] rounded-lg p-4 flex items-center justify-between select-none"
          >
            <!-- Left Section: Page Info -->
            <div class="flex items-center gap-2">
              <img
                src="@/assets/search.webp"
                alt="Search"
                class="w-10 h-10 sm:w-14 sm:h-14 object-contain"
              />
              <span class="text-white font-bold text-base sm:text-lg font-montserrat">
                {{ pageStart }} - {{ pageEnd }} of {{ totalDocs }} items
              </span>
            </div>

            <!-- Pagination Controls -->
            <div class="flex items-center gap-2">
              <!-- Previous Button -->
              <button
                class="p-2 bg-[#5A2E80] rounded-md disabled:opacity-50"
                :disabled="currentPage === 1"
                @click="prevPage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>

              <!-- Next Button -->
              <button
                class="p-2 bg-[#5A2E80] rounded-md disabled:opacity-50"
                :disabled="currentPage === totalPages"
                @click="nextPage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import axios from 'axios';
import axios from "axios";
// import { watch } from 'vue';
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
  name: "GuessTheBalancePage",
  data() {
    return {
      isReady: false,
      totalBonusHunts: 0,
      totalCost: 0,
      totalPayout: 0,
      totalProfit: 0,
      animatedBonusHunts: 0,
      animatedTotalCost: 0,
      animatedTotalPayout: 0,
      animatedTotalProfit: 0,
      loading: false,
      currentGTBSession: null,
      previousGTBSession: [],
      filteredGTBSession: [],
      selectedCategory: "All",
      dropdownOpen: false,
      currentPage: 1,
      totalPages: 1,
      totalDocs: 0,
      limit: 10,
    };
  },
  computed: {
    pageStart() {
      return this.totalDocs === 0 ? 0 : (this.currentPage - 1) * this.limit + 1;
    },
    pageEnd() {
      return Math.min(this.currentPage * this.limit, this.totalDocs);
    },
  },
  methods: {
    async fetchGTBSessions() {
      if (this.loading) return;
      this.loading = true;

      try {
        const baseUrl = process.env.VUE_APP_BASE_API;
        const params = { page: this.currentPage, limit: this.limit };

        if (this.selectedCategory !== "All") {
          params.category = this.selectedCategory;
        }

        const response = await axios.get(`${baseUrl}/api/guess-the-balance`, {
          params,
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });

        // console.log("GTB API Response:", response.data);

        if (response.data && Array.isArray(response.data.gtbs)) {
          this.leaderboardData = []; // Reset leaderboard before updating
          this.processGTBData(response.data.gtbs);
          this.totalPages = response.data.totalPages || 1;
          this.totalDocs = response.data.totalDocs || 0;
        } else {
          console.warn("Unexpected API response format:", response.data);
        }

        // Ensure totalGTBSessions and totalGTBSpent are updated correctly
        if (response.data.gtbSummary) {
          this.totalBonusHunts = response.data.gtbSummary.totalBonusHunts || 0;
          this.totalCost = response.data.gtbSummary.totalCost || 0;
          this.totalPayout = response.data.gtbSummary.totalPayout || 0;
          this.totalProfit = response.data.gtbSummary.totalProfit || 0;

          // Animate values after setting them
          this.animateValues();
        }
      } catch (error) {
        console.error("Error fetching GTB sessions:", error);
      } finally {
        this.loading = false;
      }
    },
    processGTBData(gtbs) {
      // Find the active GTB session first
      const activeSession = gtbs.find(
        (gtb) => gtb.status === "started" || gtb.status === "ongoing"
      );

      // Assign currentGTBSession if an active session exists
      this.currentGTBSession = activeSession
        ? {
            id: activeSession._id,
            date: new Date(activeSession.createdAt).toLocaleDateString("en-US"),
            status: activeSession.status,
            gtb: {
              startingBalance: activeSession.startingBalance || 0,
              biggestWin: activeSession.biggestWin || 0,
              highestMultiplier: activeSession.highestMultiplier || 0,
              bonuses: activeSession.bonuses || 0,
            },
            totalProfit:
              activeSession.endingBalance !== null &&
              activeSession.startingBalance !== null
                ? activeSession.endingBalance - activeSession.startingBalance
                : "TBD",
          }
        : null;

      // Filter out previous GTB sessions (exclude active session if it exists)
      this.previousGTBSession = gtbs
        .filter((gtb) => gtb._id !== activeSession?._id) // Exclude active session safely
        .map((gtb) => ({
          id: gtb._id,
          date: new Date(gtb.createdAt).toLocaleDateString("en-US"),
          createdAt: new Date(gtb.createdAt),
          status: gtb.status || "N/A",
          startingBalance: gtb.startingBalance || 0,
          biggestWin: gtb.biggestWin || 0,
          bonuses: gtb.bonuses || 0,
          highestMultiplier: gtb.highestMultiplier || 0,
          endingBalance: gtb.endingBalance || 0,
          profitLoss:
            gtb.endingBalance !== null && gtb.startingBalance !== null
              ? gtb.endingBalance - gtb.startingBalance
              : "TBD",
          winner: gtb.winner || "N/A",
          category: gtb.category || "Uncategorized",
          prize: gtb.prize,
        }));

      this.filteredGTBSession = [...this.previousGTBSession];

      this.animateValues();
    },

    truncateUsername(username) {
      return username
        ? username.length > 10
          ? username.substring(0, 7) + "..."
          : username
        : "TBD";
    },
    animateValues() {
      this.animateNumber("animatedBonusHunts", this.totalBonusHunts);
      this.animateNumber("animatedTotalCost", this.totalCost);
      this.animateNumber("animatedTotalPayout", this.totalPayout);
      this.animateNumber("animatedTotalProfit", this.totalProfit);
    },
    animateNumber(variable, targetValue) {
      let startValue = this[variable]
        ? parseFloat(this[variable].toString().replace(/,/g, ""))
        : 0;
      targetValue = Number(targetValue); // Ensure it's a number

      if (startValue === targetValue) return;

      const step = Math.abs(targetValue - startValue) / 30; // Smooth animation
      const isDecreasing = startValue > targetValue;

      const interval = setInterval(() => {
        if (isDecreasing) {
          startValue -= step;
          if (startValue <= targetValue) {
            startValue = targetValue;
            clearInterval(interval);
          }
        } else {
          startValue += step;
          if (startValue >= targetValue) {
            startValue = targetValue;
            clearInterval(interval);
          }
        }

        this[variable] =
          variable === "animatedBonusHunts"
            ? Math.round(startValue).toLocaleString()
            : startValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }, 50);
    },
    refreshData() {
      this.animatedBonusHunts = 0;
      this.animatedTotalCost = 0;
      this.animatedTotalPayout = 0;
      this.animatedTotalProfit = 0;

      this.fetchGTBSessions().then(() => {
        setTimeout(() => {
          this.animateValues();
        }, 100);
      });
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectCategory(category) {
      this.selectedCategory =
        category === "GTB Bonus Buy" || category === "GTB Spins Hunt" ? category : "All";
      this.dropdownOpen = false;
      this.fetchGTBSessions();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchGTBSessions();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchGTBSessions();
      }
    },
  },
  mounted() {
    this.fetchGTBSessions();
  },
};
</script>

<style scoped>
/* Smooth fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.timer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px; /* Reduced width for mobile */
  height: 90px; /* Reduced height for mobile */
  background-color: #20122e;
  border-radius: 10px;
  border: 2px solid #411d35;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: center;
}

.timer-box h4 {
  font-size: 36px; /* Reduced font size for mobile */
  color: white;
  font-weight: bold;
}

.timer-box h6 {
  font-size: 14px; /* Adjusted for better mobile readability */
  color: white;
  text-transform: uppercase;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .timer-box {
    width: 90px;
    height: 90px;
  }

  .timer-box h4 {
    font-size: 36px;
  }

  .timer-box h6 {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .timer-box {
    width: 60px; /* Further reduced for very small screens */
    height: 60px;
  }

  .timer-box h4 {
    font-size: 20px;
  }

  .timer-box h6 {
    font-size: 10px;
  }
}

/* Ensure the timer boxes wrap correctly */
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px; /* Space between timer boxes */
}

.border-gradient {
  position: relative;
  border-radius: 8px;
}

.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(90deg, #d19e00 0%, #c775df 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gold-glow {
  text-shadow: 0px 0px 4px #ffd700;
}

.red-glow {
  text-shadow: 0px 0px 4px #ff0000; /* Red Glow */
}

.green-glow {
  text-shadow: 0px 0px 4px #00ff00; /* Green Glow */
}

.loader {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
