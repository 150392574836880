<template>
  <!-- Footer start -->
  <footer
    class="relative w-full h-full px-4 py-10 bg-[#20122E] lg:py-20 lg:z-30"
  >
    <div
      class="flex flex-col lg:px-8 xl:px-20 max-w-[1400px] sm:max-w-[600px] md:max-w-[900px] lg:max-w-[1400px] mx-auto"
    >
      <!-- Main Row: Logo, Navigation Links, Social Icons -->
      <div
        class="flex flex-col items-center justify-between w-full gap-6 lg:flex-row"
      >
        <!-- Logo Section -->
        <div class="flex items-center gap-2">
          <!-- <a @click="showMenu = false; this.isScrolled = false;" href="">
            <img v-motion-pop-visible alt="logo" src="@/assets/nav-logo.png" />
          </a> -->
          <a
            href="#home"
            class="flex items-center justify-start gap-x-2 sm:gap-x-4"
            ><img
              v-motion-pop-visible
              src="@/assets/mikhailis-logo.png"
              class="w-auto h-[52px] object-contain"
              alt="logo"
          /></a>
        </div>

       <!-- Navigation Links -->
       <div class="flex flex-wrap justify-center gap-4 text-lg text-white uppercase font-lato">
          <router-link
          v-motion-pop-visible
            v-for="item in navItems"
            :key="item.id"
            :to="item.path"
            :class="[
              'block w-full text-center px-4 py-2 transition-colors lg:inline-block lg:w-auto',
              {
                'font-black text-[#FFD703]': isActiveRoute(item.path),
                'text-white hover:text-[#e4dfdf]': !isActiveRoute(item.path),
              },
            ]"
          >
            {{ item.name }}
          </router-link>
        </div>

        <!-- Social Icons + Copyright -->
        <div class="flex flex-col items-center gap-0">
          <!-- Empty space with consistent height -->
          <p
            class="h-4 text-sm font-bold font-oxanium"
            style="visibility: hidden"
          >
            &nbsp;
            <!-- Non-breaking space to ensure it renders -->
          </p>

          <!-- Icons Row -->
          <div
            v-motion-pop-visible
            class="flex flex-row items-center justify-center gap-x-1.5"
          >
            <a href="https://kick.com/Mikhailis" target="_blank">
              <img src="@/assets/kick-footer.png" class="h-[32px] w-[32px]" />
            </a>
            <a href="https://www.youtube.com/@GambaGolds" target="_blank">
              <img
                src="@/assets/youtube-footer.png"
                class="h-[48px] w-[48px]"
              />
            </a>
            <a href="#" target="_blank">
              <img
                src="@/assets/instagram-footer.png"
                class="h-[34px] w-[34px] object-contain"
              />
            </a>
            <a href="https://twitter.com/MikhailisGG" target="_blank">
              <img src="@/assets/x-footer.png" class="h-[34px] w-[34px]" />
            </a>
            <a href="https://discord.com/invite/gambagold" target="_blank">
              <img
                src="@/assets/discord-footer.png"
                class="h-[40px] w-[40px] object-contain"
              />
            </a>
          </div>
        </div>
      </div>

      <!-- Centered Paragraphs Section -->
      <div
        class="flex flex-col items-start w-full gap-4 px-4 py-4 mt-4 text-base font-normal text-center lg:flex-row lg:text-start lg:px-0 font-lato"
      >
        <div v-motion-pop-visible>
          <p class="text-white lg:text-justify">
            If you ever feel that gambling is becoming a problem, we urge you to
            seek help and support. Most people gamble for fun and enjoyment.
            However, some people think of gambling as a way to make money, spend
            more than they can afford, or use gambling to distract themselves
            from everyday problems.
          </p>
          <p class="mt-4 text-white font-oxanium">
            To find further advice, help and support visit:
            <span class="underline text-inherit text-[#FFD700]">
              <a href=" https://www.begambleaware.org/" target="_blank">
                https://www.begambleaware.org/
              </a></span
            >
          </p>
        </div>
        <div v-motion-pop-visible>
          <p class="text-white lg:text-justify">
            We do not take responsibility for misunderstood promotions, losses
            from gambling in casinos or betting on sites which are linked to or
            promoted on this website. Make sure it is legal for you to use each
            site inside your jurisdiction and ALWAYS gamble responsibly!
          </p>
        </div>
      </div>
    </div>
  </footer>
  <div
    class="flex justify-center font-normal font-internet text-[12px] items-center text-white w-full h-[60px] bg-gradient-to-r from-[#412F00] via-[#A77800] to-[#412F00]"
  >
    @2025 MIKHAILIS - ALL RIGHTS RESERVED
  </div>
  <!-- Footer end -->
</template>

<script>
export default {
  name: "FooterElement",
  data() {
    return {
      navItems: [
        { id: "home", name: "Home", path: "/" },
        { id: "leaderboards", name: "Leaderboards", path: "/leaderboards" },
        { id: "store", name: "Store", path: "/store" },
        { id: "guess-the-balance", name: "Guess The Balance", path: "/guess-the-balance" },
      ],
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  methods: {
    isActiveRoute(routePath) {
      return this.currentRoute === routePath;
    },
  },
};
</script>