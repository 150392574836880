<template>
  <div class="relative">
    <nav
      :class="{
        'bg-[#120E21] backdrop-blur-0': !isScrolled,
        'bg-[#120E21]': isSidebarOpen,
      }"
      class="fixed top-0 left-0 w-full px-4 py-3.5 backdrop-blur-md bg-opacity-95 transition-all duration-300 ease-in-out z-50"
    >
      <div
        class="container px-10 lg:px-10 xl:px-20 mx-auto max-w-[1400px] flex justify-between items-center"
      >
        <!-- Logo & Desktop Navigation -->
        <div class="flex items-center gap-8">
          <router-link to="/" class="flex items-center gap-x-4">
            <img
              src="@/assets/mikhailis-logo.png"
              class="w-auto h-[52px] object-contain"
              alt="logo"
            />
          </router-link>
          <div class="items-center hidden gap-6 text-lg lg:flex font-lato">
            <router-link
              v-for="item in navItems"
              :key="item.id"
              :to="item.path"
              :class="[
                'text-[18px] px-4 py-2 rounded transition-all duration-300 ease-in-out font-montserrat',
                {
                  'font-black text-[#FFD703]': isActiveRoute(item.path),
                  'text-white hover:text-white/80': !isActiveRoute(item.path),
                },
              ]"
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>

        <!-- Status Toggle & Login (Desktop) -->
        <div class="items-center hidden md:flex gap-x-4">
          <div
            class="flex items-center px-2 py-1 rounded-full bg-gradient-to-r w-[110px] h-[40px] relative cursor-pointer transition-all duration-300 ease-in-out"
            :class="
              isLive ? 'from-green-400 to-green-600' : 'from-[#383838] to-[#B4B4B4]'
            "
            @click="redirectToKick"
          >
            <transition name="fade">
              <span
                v-if="isLive"
                key="live"
                class="absolute text-sm font-bold text-black left-4"
                >Live</span
              >
              <span
                v-else
                key="offline"
                class="absolute text-sm font-bold text-black right-4"
                >Offline</span
              >
            </transition>
            <transition name="slide">
              <div
                class="flex items-center justify-center w-8 h-8 bg-[#20122E] rounded-full absolute transition-transform duration-300"
                :class="{
                  'translate-x-[64px]': isLive,
                  'translate-x-0': !isLive,
                }"
              >
                <span class="text-lg font-bold text-green-400">K</span>
              </div>
            </transition>
          </div>
          <button
            v-if="$store.state.user == null"
            @click="handleLogin()"
            type="button"
            class="px-6 py-2 font-bold text-black font-montserrat bg-[#FFD703] rounded-lg"
          >
            LOGIN
          </button>
          <!-- Profile Dropdown -->
          <div class="relative" v-if="$store.state.user != null">
            <div class="flex items-center cursor-pointer gap-2" @click="toggleDropdown">
              <!-- Profile Image -->
              <div class="relative w-10 h-10 rounded-full border-2 border-yellow-400">
                <img
                  :src="$store.state.avatar || require('@/assets/navbar-avatar.png')"
                  alt="User Avatar"
                  class="w-full h-full rounded-full object-cover"
                />
              </div>

              <!-- Arrow Icon -->
              <svg
                class="w-4 h-4 text-white transition-transform"
                :class="{ 'rotate-180': dropdownOpen }"
                fill="white"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>

            <!-- Dropdown Menu -->
            <div
              v-if="dropdownOpen"
              class="absolute right-0 mt-2 w-48 bg-[#4D2C6F] text-white rounded-lg shadow-lg z-50"
            >
              <!-- Profile -->
              <a
                href="/profile"
                class="flex items-center gap-2 px-4 py-3 hover:bg-[#6A3B94]"
              >
                <svg class="w-5 h-5" fill="white" viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M10 12a5 5 0 100-10 5 5 0 000 10zm-7 6a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="text-white">Profile</span>
              </a>

              <!-- Logout -->
              <button
                @click="handleLogout"
                class="w-full flex items-center gap-2 px-4 py-3 text-left hover:bg-[#6A3B94]"
              >
                <svg class="w-5 h-5" fill="white" viewBox="0 0 24 24">
                  <path
                    fill="white"
                    d="M14.08 15.59L16.67 13H7v-2h9.67l-2.59-2.59L15.5 7l4.5 4.5-4.5 4.5-1.42-1.41ZM5 19h7v2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h7v2H5v14Z"
                  />
                </svg>
                <span class="text-white">Logout</span>
              </button>
            </div>
          </div>
        </div>

        <!-- Mobile Hamburger Button -->
        <div class="lg:hidden">
          <button @click="toggleSidebar" class="focus:outline-none">
            <svg
              v-if="!isSidebarOpen"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              class="w-9 h-9"
            >
              <path
                fill-rule="evenodd"
                d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              class="w-9 h-9"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>

    <!-- Sidebar -->
    <div
      :class="{
        'translate-x-0 w-[85%]': isSidebarOpen,
        '-translate-x-full': !isSidebarOpen,
      }"
      class="fixed top-0 left-0 h-full bg-opacity-70 backdrop-blur-lg bg-[#2E193B] transform transition-transform duration-300 ease-in-out z-30"
    >
      <div class="mt-[85px] text-white font-bold font-lato text-lg uppercase">
        <router-link
          v-for="item in navItems"
          :key="item.id"
          :to="item.path"
          @click="toggleSidebar"
          class="block w-full px-6 py-4 mb-1 text-left transition duration-200"
          :class="{ 'bg-[#69DFFF1d] text-[#FFD703]': isActiveRoute(item.path) }"
        >
          {{ item.name }}
        </router-link>

        <router-link
          to="/profile"
          @click="toggleSidebar"
          class="block w-full px-6 py-4 mb-1 text-left transition duration-200"
          :class="{ 'bg-[#69DFFF1d] text-[#FFD703]': isActiveRoute('/profile') }"
        >
          Profile
        </router-link>
      </div>
      <div class="p-6">
        <button
          v-if="$store.state.user == null"
          @click="handleLogin()"
          type="button"
          class="block w-full text-center px-6 py-3 font-bold text-black font-montserrat bg-[#FFD703] rounded-md transition duration-200 hover:bg-[#FFC300]"
        >
          LOGIN
        </button>

        <button
          v-if="$store.state.user != null"
          @click="handleLogout()"
          type="button"
          class="block w-full text-center px-6 py-3 font-bold text-black font-montserrat bg-[#FFD703] rounded-md transition duration-200 hover:bg-[#FFC300]"
        >
          LOGOUT
        </button>
      </div>

      <div class="flex-grow"></div>

      <!-- Kick Status Toggle (Always at Bottom & Centered) -->
      <div class="flex items-center h-[440px] justify-center w-full pb-6">
        <div
          class="flex items-center px-2 py-1 rounded-full bg-gradient-to-r w-[110px] h-[40px] relative cursor-pointer transition-all duration-300 ease-in-out"
          :class="isLive ? 'from-green-400 to-green-600' : 'from-[#383838] to-[#B4B4B4]'"
          @click="redirectToKick"
        >
          <transition name="fade">
            <span
              v-if="isLive"
              key="live"
              class="absolute text-sm font-bold text-black left-4"
              >Live</span
            >
            <span
              v-else
              key="offline"
              class="absolute text-sm font-bold text-black right-4"
              >Offline</span
            >
          </transition>
          <transition name="slide">
            <div
              class="flex items-center justify-center w-8 h-8 bg-[#20122E] rounded-full absolute transition-transform duration-300"
              :class="{
                'translate-x-[64px]': isLive,
                'translate-x-0': !isLive,
              }"
            >
              <span class="text-lg font-bold text-green-400">K</span>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- Sidebar Overlay -->
    <div
      v-if="isSidebarOpen"
      @click="closeSidebar"
      class="fixed inset-0 z-20 transition-opacity duration-300 ease-in-out bg-opacity-75 bg-gray-950"
    ></div>

    <div class="hidden">
      <iframe :src="`https://player.kick.com/${streamer}`" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarElement",
  data() {
    return {
      isScrolled: false,
      isSidebarOpen: false,
      isLive: false,
      streamer: "Mikhailis",
      dropdownOpen: false,
      navItems: [
        { id: "home", name: "Home", path: "/" },
        { id: "leaderboards", name: "Leaderboards", path: "/leaderboards" },
        { id: "store", name: "Store", path: "/store" },
        {
          id: "guess-the-balance",
          name: "Guess The Balance",
          path: "/guess-the-balance",
        },
      ],
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  methods: {
    handleLogin() {
      const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`;
      window.location.href = loginUrl;

      //   console.log(store.state.user)
    },
    handleLogout() {
      // Clear Vuex state
      this.$store.commit("UPDATE_USER", null);

      // Remove local storage items
      localStorage.removeItem("vuex");
      localStorage.removeItem("accessToken");

      // Redirect to login/home page
      this.$router.replace("/");

      // Close sidebar if open
      this.isSidebarOpen = false;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    isActiveRoute(routePath) {
      return this.currentRoute === routePath;
    },
    async checkStreamStatus() {
      try {
        const response = await fetch(`https://kick.com/api/v2/channels/${this.streamer}`);
        const data = await response.json();
        this.isLive = data?.livestream?.is_live ?? false;
      } catch (error) {
        console.error("Error checking stream status:", error);
      }
    },
    redirectToKick() {
      window.open(`https://kick.com/${this.streamer}`, "_blank");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.checkStreamStatus();
    setInterval(this.checkStreamStatus, 10000);
    console.log(this.$store.state);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-10px);
}
</style>
