<template>
    <div
      v-if="ticket"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50"
    >
      <div
        class="relative w-[420px] sm:w-[500px] bg-[#20122E] py-8 px-6 rounded-2xl text-center flex flex-col items-center overflow-hidden"
      >
        <!-- Close Button -->
        <button
          @click="$emit('closeSuccessModal')"
          class="absolute top-4 right-6 text-white text-4xl font-bold hover:text-gray-300 transition"
        >
          &times;
        </button>
  
        <div class="mt-10">
          <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md">
            Purchase Successful!
          </h2>
          <p class="text-white text-sm px-4 leading-relaxed mt-4">
            🎉 You have successfully purchased an item! <br> Here is your ticket:
          </p>
  
          <div
            class="mt-4 px-6 py-2 bg-[#161024] text-yellow-400 font-semibold rounded-lg shadow-md"
          >
            {{ ticket }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      ticket: String,
    },
  };
  </script>
  