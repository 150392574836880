<template>
    <div
      v-if="showRedirectModal"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50"
    >
      <div
        class="relative w-[420px] sm:w-[500px] bg-[#20122E] py-8 px-6 rounded-2xl text-start flex flex-col items-center overflow-hidden"
      >
        <!-- Close (X) Button -->
        <button
          @click.stop="$emit('closeRedirectModal')" 
          class="absolute top-4 right-6 text-white text-4xl font-bold hover:text-gray-300 transition"
        >
          &times;
        </button>
  
        <div class="text-center mt-10">
          <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md">
            Complete Your Registration
          </h2>
          <p class="text-white text-sm px-4 leading-relaxed mt-4">
            To continue, you need to register on our official 
            <span class="font-bold text-[#EDB90A]">Discord server</span>.  
          </p>
          <p class="text-white text-sm px-4 leading-relaxed mt-2">
            This step is required to verify your identity and participate in our program.  
            Click the button below to join now.
          </p>
  
          <a
            href="https://discord.com/channels/1207142959477162004/1344766394243354654"
            target="_blank"
            class="mt-6 px-6 py-2 bg-yellow-500 text-black font-semibold rounded-lg shadow-lg hover:bg-yellow-400 transition inline-block"
          >
            Go to Discord
          </a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showRedirectModal: Boolean,
    },
  };
  </script>
  