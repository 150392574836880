<template>
  <!-- Navbar start -->
  <NavbarElement :activeSection="activeSection" />
  <!-- Navbar end -->

  <router-view @sections-ready="setupIntersectionObserver"></router-view>

  <!-- Footer start -->
  <FooterElement :activeSection="activeSection" />
  <!-- Footer end -->
</template>

<script>
// import axios from 'axios';
import NavbarElement from "@/components/NavbarElement.vue";
import FooterElement from "@/components/FooterElement.vue";
import axios from "axios";
// import LoadingElement from '@/components/LoadingElement.vue';
export default {
  components: {
    FooterElement,
    NavbarElement,
    // LoadingElement
  },
  data() {
    return {
      activeSection: "home",
      observer: null,
      // loading: false
    };
  },
  // mounted() {
  // 	const code = this.$route.query.code;
  // 	if (code != null) {
  // 		this.loading = true
  // 		let formData = {
  // 			code: code
  // 		};
  // 		axios.post(process.env.VUE_APP_SERVER_URL + '/auth/twitchLogin', formData, { headers: { 'Content-Type': 'application/json' } }).then((res) => {
  // 			if (res) {
  // 				localStorage.setItem("oeg-access-token", res.data.accessToken)
  // 				this.$store.commit('UPDATE_USER', res.data.accessToken)
  // 				this.loading = false
  // 				// this.$router.replace("/profile")
  // 			}
  // 		})
  // 	}

  // },
  methods: {
    handleLogout() {
      this.loading = true;

      this.$store.commit("UPDATE_USER", null);
      localStorage.removeItem("vuex");
      localStorage.removeItem("accessToken");

      this.$router.replace("/");

      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    // setupIntersectionObserver() {
    //   this.observer = new IntersectionObserver(
    //     (entries) => {
    //       entries.forEach((entry) => {
    //         if (entry.isIntersecting) {
    //           this.activeSection = entry.target.id;
    //         }
    //       });
    //     },
    //     { threshold: 0.1 }
    //   );

    //   ["home", "leaderboards", "store", "guess-the-balance"].forEach((id) => {
    //     const element = document.getElementById(id);
    //     if (element) this.observer.observe(element);
    //   });
    // },
  },
  // beforeUnmount() {
  //   if (this.observer) {
  //     ["home", "leaderboards", "store", "guess-the-balance"].forEach((id) => {
  //       const element = document.getElementById(id);
  //       if (element) this.observer.unobserve(element);
  //     });
  //   }
  // },
  mounted() {
    const accessToken = this.$route.query.accessToken;
    if (accessToken != null || accessToken != undefined) {
      this.loading = true;
      axios
        .get(process.env.VUE_APP_BASE_API + "/api/user", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          if (res) {
            const user = res.data.user;
            const picUrl = `https://cdn.discordapp.com/avatars/${user.discordId}/${user.profileImage}.png`;

            this.$store.commit("UPDATE_USER", user);
            this.$store.commit("UPDATE_AVATAR", { avatar: picUrl });

            localStorage.setItem("accessToken", accessToken);

            setTimeout(() => {
              this.loading = false;
              this.$router.replace("/profile");
            }, 2000);
          }
        });
    }
  },
};
</script>
