<template>
    <div
      v-if="showModal"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50"
    >
      <div
        class="relative w-[350px] sm:w-[500px] bg-[#20122E] py-6 px-4 rounded-2xl text-center flex flex-col items-center overflow-hidden"
      >
  
        <div class="relative z-10 w-full">
          <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md">
            Profile Locked
          </h2>
  
          <p class="text-white text-lg mt-4 px-6">
            You can update your profile in
            <span class="font-bold text-[#FCCC67] text-2xl">{{ daysLeft }}</span>
            days.
          </p>
  
          <div class="mt-6 flex justify-center">
            <button
              @click="$emit('close')"
              class="px-6 py-2 bg-yellow-500 text-black font-semibold rounded-lg shadow-lg hover:bg-yellow-400"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showModal: Boolean,
      daysLeft: Number,
    },
  };
  </script>
  