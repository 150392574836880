import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import ErrorView from '../views/ErrorView.vue'
import MainPage from '../pages/MainPage.vue'
import LeaderboardPage from '@/pages/LeaderboardPage.vue'
import StorePage from '@/pages/StorePage.vue'
import GuessTheBalancePage from '@/pages/GuessTheBalancePage.vue'
import GuessTheBalanceDetails from '@/pages/GuessTheBalanceDetails.vue'
import ProfilePage from '@/pages/ProfilePage.vue'

const routes = [
  {
    path: '/',
    component: MainView,
    meta: { alreadyAuth: true },
    children: [
      {
        path: '/',
        alias: '/',
        component: MainPage
      },
      {
        path: '/leaderboards',
        component: LeaderboardPage
      },
      {
        path: '/store',
        component: StorePage
      },  
      {
        path: '/profile',
        component: ProfilePage
      },  
      {
        path: '/guess-the-balance',
        component: GuessTheBalancePage
      },  
      {
        path: '/guess-the-balance/:id',  
        component: GuessTheBalanceDetails, 
      },
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorView',
    component: ErrorView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { top: 0}
  },
})


export default router
