<template>
  <div
    class="relative h-full lg:h-[1000px] pb-10 overflow-hidden bg-gradient-to-b from-[#0A0D1D] to-[#0A0D1D]"
  >
    <!-- Overlays -->
    <img
      src="@/assets/overlay-1.webp"
      class="hidden lg:flex absolute top-[160px] -left-[160px] lg:-left-[200px] xl:-left-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 1"
    />
    <img
      src="@/assets/overlay-2.webp"
      class="hidden lg:flex absolute top-[500px] right-[260px] lg:-right-[220px] xl:-right-[100px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 2"
    />

    <section
      id="profile"
      class="px-4 sm:px-6 py-16 lg:px-16 2xl:px-56 pt-[120px] lg:pt-[120px]"
    >
      <div class="flex flex-col items-center">
        <!-- Profile & Accounts -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full max-w-4xl">
          <!-- Profile Card -->
          <div
            class="bg-[#20122E] p-6 rounded-lg flex flex-col md:flex-row justify-start items-center md:items-center"
          >
            <img
              :src="picUrl || require('@/assets/avatar.png')"
              class="w-28 h-28 object-contain rounded-full aspect-square mb-2 md:mr-4"
              alt="User Avatar"
            />
            <div
              class="text-center md:text-left font-montserratflex flex flex-col gap-y-3 lg:gap-y-1"
            >
              <h2 class="text-white text-2xl font-black">{{ displayName }}</h2>
              <!-- <p class="text-sm text-[#FFD700]">Roobet Member</p> -->
              <p class="text-sm font-black text-white mt-1">ID: {{ discordID }}</p>
              <p class="text-sm font-black text-white">Store Points: {{ points }}</p>
            </div>
          </div>

          <!-- Accounts Card -->
          <div class="bg-[#20122E] p-6 rounded-lg">
            <div class="flex justify-between items-center mb-3">
              <h3 class="text-white font-bold text-lg">Accounts</h3>
              <button
               @click="checkEditPermission"
                title="You can update your details every 60 days"
                class="group"
              >
                <img
                  src="../assets/edit.webp"
                  class="w-5 h-5 cursor-pointer hover:opacity-80 transition"
                  alt="Edit Icon"
                />
              </button>
            </div>
            <div class="text-[#7F85A3] text-sm space-y-2">
              <div class="flex justify-between">
                <span class="font-semibold text-white">Roobet Username:</span>
                <span class="text-[#7F85A3]">{{ roobet }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-semibold text-white">Rain.GG Username:</span>
                <span class="text-[#7F85A3]">{{ raingg }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-semibold text-white">Upgrader Username:</span>
                <span class="text-[#7F85A3]">{{ upgrader }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-semibold text-white">Kick Username:</span>
                <span class="text-[#7F85A3]">{{ kick }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-semibold text-white">Twitter / X:</span>
                <span class="text-[#7F85A3]">{{ twitter }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Disclaimer Section -->
        <div class="bg-[#20122E] p-6 rounded-lg mt-6 w-full max-w-4xl">
          <h3 class="text-white font-bold text-lg mb-3">Disclaimer</h3>
          <ul class="text-[#7F85A3] text-sm list-decimal list-inside space-y-1">
            <li class="text-[#7F85A3]">
              Users must put the correct information to avoid discrepancies in points or
              else points won't be distributed correctly.
            </li>
            <li class="text-[#7F85A3]">
              Any loss of points, the mikhailis team will not be held liable due to
              incorrect information entered on the profile page.
            </li>
            <li class="text-[#7F85A3]">
              All info with red (*) must be entered properly above as they are your unique
              account identification per casino.
            </li>
          </ul>
        </div>
      </div>
    </section>
    <OnboardModal
      :showOnBoardModal="showOnBoardModal"
      @closeOnBoardModal="closeOnBoardModal"
      @refreshUserData="init"
    />
    <RedirectModal
      :showRedirectModal="showRedirectModal"
      @closeRedirectModal="closeRedirectModal"
    />
    <ProfileLockModal
      :showModal="showProfileLockModal"
      :daysLeft="daysLeft"
      @close="showProfileLockModal = false"
    />
  </div>
</template>

<script>
import OnboardModal from "@/components/OnboardModal.vue";
import ProfileLockModal from "@/components/ProfileLockModal.vue";
import RedirectModal from "@/components/RedirectModal.vue";
import axios from "axios";

export default {
  name: "ProfilePage",
  components: {
    OnboardModal,
    RedirectModal,
    ProfileLockModal,
  },
  data() {
    return {
      isReady: false,
      displayName: null,
      picUrl: null,
      discordID: null,
      twitter: null,
      roobet: null,
      raingg: null,
      upgrader: null,
      kick: null,
      points: 0,
      daysLeft: 0,
      showOnBoardModal: false,
      showRedirectModal: false,
      showProfileLockModal: false,
      loading: false,
    };
  },
  methods: {
    init() {
      const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage

      if (token) {
        this.$store.commit("UPDATE_USER", { accessToken: token });

        axios
          .get(process.env.VUE_APP_BASE_API + "/api/user", {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data) {
              this.displayName = res.data.user.username;
              this.picUrl = `https://cdn.discordapp.com/avatars/${res.data.user.discordId}/${res.data.user.profileImage}.png`;
              this.discordID = res.data.user.discordId;
              this.twitter = res.data.user.twitter;
              this.roobet = res.data.user.attributes.roobet;
              this.raingg = res.data.user.raingg;
              this.upgrader = res.data.user.upgrader;
              this.kick = res.data.user.attributes.kick;
              this.points = res.data.user.attributes.points;

              const lastUpdated = new Date(res.data.user.updatedAt);
              const currentDate = new Date();
              const daysSinceUpdate = Math.floor(
                (currentDate - lastUpdated) / (1000 * 60 * 60 * 24)
              );

              // Show the profile lock modal if less than 60 days have passed
              if (daysSinceUpdate < 60) {
                this.daysLeft = 60 - daysSinceUpdate;
              }

              // this.showModal = !(this.twitter);
              this.showRedirectModal = !this.roobet;
            }
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              localStorage.removeItem("vuex");
              localStorage.removeItem("accessToken");
              window.location.reload();
            } else {
              console.log(`Error: ${error.response.status}`);
            }
          });
      }
    },
    checkEditPermission() {
      if (this.daysLeft > 0) {
        this.showProfileLockModal = true; // Show Profile Lock Modal if days are left
      } else {
        this.showOnBoardModal = true; // Open OnboardModal if editing is allowed
      }
    },
    closeOnBoardModal() {
      this.showOnBoardModal = false;
    },
    closeRedirectModal() {
      this.showRedirectModal = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
/* Smooth fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.timer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px; /* Reduced width for mobile */
  height: 90px; /* Reduced height for mobile */
  background-color: #20122e;
  border-radius: 10px;
  border: 2px solid #411d35;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: center;
}

.timer-box h4 {
  font-size: 36px; /* Reduced font size for mobile */
  color: white;
  font-weight: bold;
}

.timer-box h6 {
  font-size: 14px; /* Adjusted for better mobile readability */
  color: white;
  text-transform: uppercase;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .timer-box {
    width: 90px;
    height: 90px;
  }

  .timer-box h4 {
    font-size: 36px;
  }

  .timer-box h6 {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .timer-box {
    width: 60px; /* Further reduced for very small screens */
    height: 60px;
  }

  .timer-box h4 {
    font-size: 20px;
  }

  .timer-box h6 {
    font-size: 10px;
  }
}

/* Ensure the timer boxes wrap correctly */
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px; /* Space between timer boxes */
}

.border-gradient {
  position: relative;
  border-radius: 8px;
}

.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(90deg, #d19e00 0%, #c775df 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.green-glow {
  text-shadow: 0px 0px 4px #39ff14;
}
</style>
