<template>
  <div
    class="relative h-[3260px] lg:h-[2900px] overflow-hidden bg-gradient-to-b from-[#0A0D1D] to-[#0A0D1D]"
  >
    <!-- Overlays -->
    <img
      src="@/assets/overlay-1.webp"
      class="hidden lg:flex absolute top-[160px] -left-[160px] lg:-left-[200px] xl:-left-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 1"
    />
    <img
      src="@/assets/overlay-2.webp"
      class="hidden lg:flex absolute top-[500px] right-[260px] lg:-right-[220px] xl:-right-[100px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 2"
    />
    <img
      src="@/assets/overlay-3.webp"
      class="hidden lg:flex absolute top-[1040px] left-[260px] lg:-left-[150px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 3"
    />
    <img
      src="@/assets/overlay-4.webp"
      class="hidden lg:flex absolute top-[1320px] lg:-right-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 4"
    />

    <section
      id="rewards"
      class="px-4 sm:px-6 py-16 lg:px-16 2xl:px-56 pt-[120px] lg:pt-[80px]"
    >
      <div class="flex flex-col items-center">
        <!-- Banner -->
        <div v-motion-pop-visible class="relative w-full max-w-6xl">
          <img
            src="../assets/leaderboard-banner.webp"
            alt="Rewards Banner"
            class="w-full object-contain"
          />
        </div>

        <!-- Title -->
        <div
          v-motion-pop-visible
          class="w-full max-w-6xl flex justify-center lg:justify-start items-center mt-10"
        >
          <h1
            class="font-montserrat font-bold text-white text-2xl lg:text-3xl ml-4 sm:ml-8"
          >
            How it Works
          </h1>
        </div>

        <!-- Cards -->
        <div
          class="grid w-full max-w-6xl grid-cols-1 gap-6 mt-8 sm:grid-cols-2 lg:grid-cols-3"
        >
          <div v-motion-pop-visible class="flex justify-center items-center">
            <img
              src="@/assets/leaderboard-card1.webp"
              alt="Referral"
              class="w-full max-w-full object-contain"
            />
          </div>
          <div v-motion-pop-visible class="flex justify-center items-center">
            <img
              src="@/assets/leaderboard-card2.webp"
              alt="Profile Settings"
              class="w-full max-w-full object-contain"
            />
          </div>
          <div v-motion-pop-visible class="flex justify-center items-center">
            <img
              src="@/assets/leaderboard-card3.webp"
              alt="Monthly Reset"
              class="w-full max-w-full object-contain"
            />
          </div>
        </div>

        <!-- Disclaimer Dropdown -->
        <div class="w-full max-w-6xl mt-10">
          <div
            v-motion-pop-visible
            class="bg-[#20122E] rounded-lg p-4 flex items-center justify-between cursor-pointer select-none"
            @click.prevent="toggleDisclaimer"
          >
            <div class="flex items-center gap-2">
              <img
                src="@/assets/lightbulb.webp"
                alt="Lightbulb"
                class="w-10 h-10 sm:w-14 sm:h-14"
              />
              <span class="text-white font-bold text-base sm:text-lg font-montserrat"
                >DISCLAIMER</span
              >
            </div>
            <span
              class="text-white font-bold text-lg sm:text-xl transition-transform duration-300"
              :class="{ 'rotate-180': showDisclaimer }"
            >
              ▼
            </span>
          </div>

          <transition name="fade">
            <div
              v-if="showDisclaimer"
              class="bg-[#2A1A3A] text-white p-3 sm:p-4 rounded-b-lg mt-1 text-sm sm:text-base"
            >
              Your wagers on <span class="font-bold text-[#FFD703]">Roobet</span> will
              count towards the leaderboard at the following weights based on the games
              you are playing. This helps prevent leaderboard abuse: <br /><br />
              *
              <span class="font-bold text-[#FFD703]">Games with an RTP under 96%</span>
              will contribute <span class="font-bold text-[#FFD703]">100%</span> of the
              amount wagered to the leaderboard. <br /><br />
              *
              <span class="font-bold text-[#FFD703]">Games with an RTP of 97%</span> will
              contribute <span class="font-bold text-[#FFD703]">50%</span> of the amount
              wagered to the leaderboard. <br /><br />
              *
              <span class="font-bold text-[#FFD703]"
                >Games with an RTP of 98-99% and above</span
              >
              will contribute <span class="font-bold text-[#FFD703]">10%</span> of the
              amount wagered to the leaderboard. <br /><br />
              *
              <span class="font-bold text-[#FFD703]"
                >Only house games and slots count</span
              >
              towards the leaderboard. <br /><br />
              Only Slots and Housegames count
              <span class="font-black text-[#FFD703]">(Dice excluded).</span>
            </div>
          </transition>
        </div>

        <div
          class="w-full max-w-6xl h-auto mt-6 flex flex-col sm:flex-row gap-4 justify-center"
        >
          <!-- Roobet Card -->
          <div
            v-motion-pop-visible
            @click="
              toggleLeaderboard('roobet');
              changeLeaderboard();
            "
            class="flex items-center justify-between w-full lg:w-full h-[90px] sm:h-[120px] bg-[#17112A] rounded-lg hover:cursor-pointer transition-all duration-300"
            :class="{
              'border-gradient p-6 sm:p-8': currentLeaderboard === 'roobet',
              'border border-[#2A1A3A] p-4 sm:p-6': currentLeaderboard !== 'roobet',
            }"
          >
            <div class="flex items-center gap-2">
              <img
                src="@/assets/roobet.webp"
                alt="Roobet"
                class="w-[100px] sm:w-[140px] lg:w-[160px] h-auto"
              />
            </div>
            <span
              class="font-black text-lg sm:text-xl font-montserrat transition-all duration-300"
              :class="{
                'text-[#FFD700] gold-glow': currentLeaderboard === 'roobet',
                'text-[#7F85A3]': currentLeaderboard !== 'roobet',
              }"
              >$5,000</span
            >
          </div>

          <!-- RainGG Card -->
          <div
            v-motion-pop-visible
            @click="
              toggleLeaderboard('raingg');
              changeLeaderboard();
            "
            class="flex items-center justify-between w-full lg:w-full h-[90px] sm:h-[120px] bg-[#17112A] rounded-lg hover:cursor-pointer transition-all duration-300"
            :class="{
              'border-gradient p-6 sm:p-8': currentLeaderboard === 'raingg',
              'border border-[#2A1A3A] p-4 sm:p-6': currentLeaderboard !== 'raingg',
            }"
          >
            <div class="flex items-center gap-2">
              <img
                src="@/assets/raingg.webp"
                alt="RainGG"
                class="w-[100px] sm:w-[140px] lg:w-[160px] h-auto"
              />
            </div>
            <span
              class="font-black text-lg sm:text-xl font-montserrat transition-all duration-300"
              :class="{
                'text-[#FFD700] gold-glow': currentLeaderboard === 'raingg',
                'text-[#7F85A3]': currentLeaderboard !== 'raingg',
              }"
              >5,000c</span
            >
          </div>

          <!-- Upgrader Card -->
          <div
            v-motion-pop-visible
            @click="
              toggleLeaderboard('upgrader');
              changeLeaderboard();
            "
            class="flex items-center justify-between w-full lg:w-full h-[90px] sm:h-[120px] bg-[#17112A] rounded-lg hover:cursor-pointer transition-all duration-300"
            :class="{
              'border-gradient p-6 sm:p-8': currentLeaderboard === 'upgrader',
              'border border-[#2A1A3A] p-4 sm:p-6': currentLeaderboard !== 'upgrader',
            }"
          >
            <div class="flex items-center gap-2">
              <img
                src="@/assets/upgrader.webp"
                alt="RainGG"
                class="w-[100px] sm:w-[140px] lg:w-[160px] h-auto"
              />
            </div>
            <span
              class="font-black text-lg sm:text-xl font-montserrat transition-all duration-300"
              :class="{
                'text-[#FFD700] gold-glow': currentLeaderboard === 'upgrader',
                'text-[#7F85A3]': currentLeaderboard !== 'upgrader',
              }"
              >$1,250</span
            >
          </div>
        </div>

        <div class="flex flex-col items-center">
          <!-- Leaderboard Timer -->
          <div class="flex flex-col items-center justify-center my-10 lg:mt-20 lg:mb-5">
            <div class="bg-transparent">
              <!-- Roobet Timer -->
              <div
                v-if="currentLeaderboard === 'roobet'"
                v-motion-slide-visible-bottom
                class="bg-transparent"
              >
                <div
                  v-if="roobetTimer && roobetTimer !== '0d 0h 0m 0s'"
                  class="text-white text-base sm:text-2xl md:text-[24px] font-bold py-[11px] font-montserrat text-center"
                >
                  Roobet Leaderboard ends in
                </div>
                <div
                  v-else
                  class="text-white text-base sm:text-2xl md:text-[26px] text-center w-full font-bold font-montserrat"
                >
                  Roobet Leaderboard Ended
                </div>
                <div v-if="roobetTimer" class="flex flex-row gap-2 md:gap-x-4 mt-4">
                  <div class="timer-box font-montserrat">
                    <h4>{{ roobetTimer.split(" ")[0].slice(0, -1) }}</h4>
                    <h6>DAYS</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ roobetTimer.split(" ")[1].slice(0, -1) }}</h4>
                    <h6>HRS</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ roobetTimer.split(" ")[2].slice(0, -1) }}</h4>
                    <h6>MIN</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ roobetTimer.split(" ")[3].slice(0, -1) }}</h4>
                    <h6>SEC</h6>
                  </div>
                </div>
              </div>

              <!-- RainGG Timer -->
              <div
                v-if="currentLeaderboard === 'raingg'"
                v-motion-slide-visible-bottom
                class="bg-transparent"
              >
                <div
                  v-if="rainGGTimer && rainGGTimer !== '0d 0h 0m 0s'"
                  class="text-white text-base sm:text-2xl md:text-[24px] md:leading-[48px] font-bold py-[11px] font-montserrat text-center"
                >
                  Rain.GG Leaderboard ends in
                </div>
                <div
                  v-else
                  class="text-white text-base sm:text-2xl md:text-[26px] text-center w-full font-bold font-montserrat"
                >
                  Rain.GG Leaderboard Ended
                </div>
                <div v-if="rainGGTimer" class="flex flex-row gap-2 md:gap-x-4 mt-4">
                  <div class="timer-box font-montserrat">
                    <h4>{{ rainGGTimer.split(" ")[0].slice(0, -1) }}</h4>
                    <h6>DAYS</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ rainGGTimer.split(" ")[1].slice(0, -1) }}</h4>
                    <h6>HRS</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ rainGGTimer.split(" ")[2].slice(0, -1) }}</h4>
                    <h6>MIN</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ rainGGTimer.split(" ")[3].slice(0, -1) }}</h4>
                    <h6>SEC</h6>
                  </div>
                </div>
              </div>

              <!-- Upgrader Timer -->
              <div
                v-if="currentLeaderboard === 'upgrader'"
                v-motion-slide-visible-bottom
                class="bg-transparent"
              >
                <div
                  v-if="upgraderTimer && upgraderTimer !== '0d 0h 0m 0s'"
                  class="text-white text-base sm:text-2xl md:text-[24px] md:leading-[48px] font-bold py-[11px] font-montserrat text-center"
                >
                  Upgrader Leaderboard ends in
                </div>
                <div
                  v-else
                  class="text-white text-base sm:text-2xl md:text-[26px] text-center w-full font-bold font-montserrat"
                >
                  Upgrader Leaderboard Ended
                </div>
                <div v-if="upgraderTimer" class="flex flex-row gap-2 md:gap-x-4 mt-4">
                  <div class="timer-box font-montserrat">
                    <h4>{{ upgraderTimer.split(" ")[0].slice(0, -1) }}</h4>
                    <h6>DAYS</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ upgraderTimer.split(" ")[1].slice(0, -1) }}</h4>
                    <h6>HRS</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ upgraderTimer.split(" ")[2].slice(0, -1) }}</h4>
                    <h6>MIN</h6>
                  </div>
                  <div class="timer-box font-montserrat">
                    <h4>{{ upgraderTimer.split(" ")[3].slice(0, -1) }}</h4>
                    <h6>SEC</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-center px-4 w-full mt-10">
          <div
            class="bg-[#20122e] border-2 border-[#411d35] text-white text-center text-sm sm:text-base md:text-lg font-semibold px-6 py-3 rounded-lg shadow-lg w-full max-w-6xl"
          >
            <p class="opacity-80 text-[#7F85A3]">
              All wagers are counted during the inclusive dates:
              <span v-if="displayDateRange" class="text-[#FFD700] font-bold mt-2">
                {{
                  currentLeaderboard === "roobet"
                    ? `${roobetStartDate} - ${roobetEndDate}`
                    : currentLeaderboard === "raingg"
                    ? `${rainGGStartDate} - ${rainGGEndDate}`
                    : `${upgraderStartDate} - ${upgraderEndDate}`
                }}
              </span>
            </p>
          </div>
        </div>

        <!-- Leaderboard Section -->
        <div class="w-full max-w-6xl mt-12 lg:mt-16">
          <h2
            v-motion-pop-visible
            class="text-white font-montserrat font-bold text-lg lg:text-2xl text-center mb-4 flex items-center justify-start gap-2 lg:ml-8"
          >
            <img src="../assets/leaderboard-arrow.webp" alt="Arrow" class="w-6 h-6" />
            Top 10
          </h2>

          <!-- Current Leaderboard Table -->
          <div class="w-full max-w-6xl mt-6">
            <div class="bg-[#17112A] rounded-lg overflow-x-auto lg:overflow-hidden">
              <table class="w-full text-left text-white font-montserrat">
                <thead class="bg-[#17112A]">
                  <tr>
                    <th class="px-6 py-3 text-[#7F85A3]"></th>
                    <th class="px-6 py-3 text-[#7F85A3]">User</th>
                    <th class="px-6 py-3 text-[#7F85A3]">Wagered</th>
                    <th class="px-6 py-3 text-[#7F85A3]">Prize</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-motion-pop-visible
                    v-for="(player, index) in topRest"
                    :key="index"
                    :class="index % 2 === 0 ? 'bg-[#2A1A3A]' : 'bg-[#17112A]'"
                  >
                    <td class="px-6 py-3 font-bold text-[#7F85A3]">{{ index + 1 }}</td>
                    <td class="px-6 py-3 text-[#7F85A3]">
                      {{
                        currentLeaderboard === "roobet"
                          ? maskString(player.username)
                          : currentLeaderboard === "upgrader"
                          ? player.user.username
                          : player.username
                      }}
                    </td>
                    <td class="px-6 py-3 text-[#7F85A3]">
                      {{
                        currentLeaderboard === "roobet"
                          ? `$${player.weightedWagered.toFixed(2)}`
                          : currentLeaderboard === "upgrader"
                          ? `$${player.wager}`
                          : `${player.wagered.toFixed(2)}c`
                      }}
                    </td>
                    <td
                      class="px-6 py-3 text-[#7F85A3] font-black"
                      :class="'text-yellow-500'"
                    >
                      {{ `${getCurrentRewardValue(index + 1)}` }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          class="w-full max-w-6xl mt-16"
        >
          <h2
            v-motion-pop-visible
            class="text-white text-2xl md:text-[24px] md:leading-[48px] font-bold py-[11px] font-montserrat text-center capitalize"
          >
            {{ previousLeaderboard === "raingg" ? "Rain.GG" : previousLeaderboard }}
            Previous Leaderboard
          </h2>

          <div class="flex justify-center px-4 w-full mt-10">
            <div
              class="bg-[#20122e] border-2 border-[#411d35] text-white text-center text-sm sm:text-base md:text-lg font-semibold px-6 py-3 rounded-lg shadow-lg w-full max-w-6xl"
            >
              <p class="opacity-80 text-[#7F85A3]">
                All wagers are counted during the inclusive dates:
                <span v-if="displayDateRange" class="text-[#FFD700] font-bold mt-2">
                  {{
                    previousLeaderboard === "roobet"
                      ? `${roobetPrevStartDate} - ${roobetPrevEndDate}`
                      : previousLeaderboard === "raingg"
                      ? `${rainGGPrevStartDate} - ${rainGGPrevEndDate}`
                      : `${upgraderPrevStartDate} - ${upgraderPrevEndDate}`
                  }}
                </span>
              </p>
            </div>
          </div>

          <!-- Previous Leaderboard Table -->
          <div class="w-full max-w-6xl mt-16">
            <div class="bg-[#17112A] rounded-lg overflow-x-auto lg:overflow-hidden">
              <table class="w-full text-left text-white font-montserrat">
                <thead class="bg-[#17112A]">
                  <tr>
                    <th class="px-6 py-3 text-[#7F85A3]"></th>
                    <th class="px-6 py-3 text-[#7F85A3]">User</th>
                    <th class="px-6 py-3 text-[#7F85A3]">Wagered</th>
                    <th class="px-6 py-3 text-[#7F85A3]">Prize</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-motion-pop-visible
                    v-for="(player, index) in topRestPrev"
                    :key="index"
                    :class="index % 2 === 0 ? 'bg-[#2A1A3A]' : 'bg-[#17112A]'"
                  >
                    <td class="px-6 py-3 font-bold text-[#7F85A3]">{{ index + 1 }}</td>
                    <td class="px-6 py-3 text-[#7F85A3]">
                      {{
                        previousLeaderboard === "roobet"
                          ? maskString(player.username)
                          : previousLeaderboard === "upgrader"
                          ? player.user.username
                          : player.username
                      }}
                    </td>
                    <td class="px-6 py-3 text-[#7F85A3]">
                      {{
                        previousLeaderboard === "roobet"
                          ? `$${player.weightedWagered.toFixed(2)}`
                          : previousLeaderboard === "upgrader"
                          ? `$${player.wager}`
                          : `${player.wagered.toFixed(2)}c`
                      }}
                    </td>
                    <td
                      class="px-6 py-3 text-[#7F85A3] font-black"
                      :class="'text-yellow-500'"
                    >
                      {{ `${getPrevRewardValue(index + 1)}` }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import axios from 'axios';
import axios from "axios";
import moment from "moment-timezone";
// import { watch } from 'vue';
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
  data() {
    return {
      top3: [],
      topRest: [],
      topRestPrev: [],
      topForMobile: [],

      showDisclaimer: false,

      roobetTimer: "" || "0d 0h 0m 0s",
      rainGGTimer: "" || "0d 0h 0m 0s",
      upgraderTimer: "" || "0d 0h 0m 0s",

      roobetLeaderboard: [],
      roobetPrevLeaderboard: [],
      rainGGLeaderboard: [],
      rainGGPrevLeaderboard: [],
      upgraderLeaderboard: [],
      upgraderPrevLeaderboard: [],

      currentLeaderboard: "roobet", // Active leaderboard
      previousLeaderboard: "roobet", // Ended leaderboard // Default selected leaderboard

      roobetStartDate: null,
      roobetEndDate: null,
      roobetPrevStartDate: null,
      roobetPrevEndDate: null,

      rainGGStartDate: null,
      rainGGEndDate: null,
      rainGGPrevStartDate: null,
      rainGGPrevEndDate: null,

      upgraderStartDate: null,
      upgraderEndDate: null,
      upgraderPrevStartDate: null,
      upgraderPrevEndDate: null,

      roobetNotFormattedEndDate: null,
      rainGGNotFormattedEndDate: null,
      upgraderNotFormattedEndDate: null,

      roobetCurrentPrizes: JSON.parse(process.env.VUE_APP_ROOBET_CURRENT_PRIZES),
      roobetPrevPrizes: JSON.parse(process.env.VUE_APP_ROOBET_PREV_PRIZES),
      rainGGCurrentPrizes: JSON.parse(process.env.VUE_APP_RAINGG_CURRENT_PRIZES),
      rainGGPrevPrizes: JSON.parse(process.env.VUE_APP_RAINGG_PREV_PRIZES),
      upgraderCurrentPrizes: JSON.parse(process.env.VUE_APP_UPGRADER_CURRENT_PRIZES),
      upgraderPrevPrizes: JSON.parse(process.env.VUE_APP_UPGRADER_PREV_PRIZES),

      loading: false,
    };
  },
  computed: {
    displayDateRange() {
      if (this.currentLeaderboard === "roobet") {
        return this.roobetStartDate && this.roobetEndDate;
      } else if (this.currentLeaderboard === "raingg") {
        return this.rainGGStartDate && this.rainGGEndDate;
      } else if (this.currentLeaderboard === "upgrader") {
        return this.upgraderStartDate && this.upgraderEndDate;
      }
      return null; // Default return if no condition matches
    },
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;

      try {
        // Fetch Roobet leaderboard first
        const roobetResponse = await axios.get(`${baseUrl}/api/leaderboard/roobet`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        });

        const roobetPrevResponse = await axios.get(
          `${baseUrl}/api/leaderboard/roobet?previous=true`,
          {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        );

        // Immediately update the UI with Roobet data
        this.roobetLeaderboard = roobetResponse.data.leaderboard || [];
        this.roobetPrevLeaderboard = roobetPrevResponse.data.leaderboard || [];
        this.roobetNotFormattedEndDate = roobetResponse.data.endDate || null;
        this.roobetStartDate = moment(roobetResponse.data.startDate).format(
          "MMMM DD, YYYY"
        );
        this.roobetEndDate = moment(roobetResponse.data.endDate)
          .subtract(1, "days")
          .format("MMMM DD, YYYY");
        this.roobetPrevStartDate = moment(roobetPrevResponse.data.startDate).format(
          "MMMM DD, YYYY"
        );
        this.roobetPrevEndDate = moment(roobetPrevResponse.data.endDate)
          .subtract(1, "days")
          .format("MMMM DD, YYYY");

        this.changeLeaderboard();
        this.startRoobetTimer();
      } catch (error) {
        console.error("Error loading Roobet leaderboard data", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchRainGGData() {
      const baseUrl = process.env.VUE_APP_BASE_API;

      try {
        const rainGGResponse = await axios.get(`${baseUrl}/api/leaderboard/raingg`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        });

        const rainGGPrevResponse = await axios.get(
          `${baseUrl}/api/leaderboard/raingg?previous=true`,
          { headers: { "x-api-key": process.env.VUE_APP_X_API_KEY } }
        );

        // Update RainGG data after it's loaded
        this.rainGGLeaderboard = rainGGResponse.data.leaderboard || [];
        this.rainGGPrevLeaderboard = rainGGPrevResponse.data.leaderboard || [];
        this.rainGGNotFormattedEndDate = rainGGResponse.data.endDate || null;
        this.rainGGStartDate = moment(rainGGResponse.data.startDate).format(
          "MMMM DD, YYYY"
        );
        this.rainGGEndDate = moment(rainGGResponse.data.endDate)
          .subtract(1, "days")
          .format("MMMM DD, YYYY");
        this.rainGGPrevStartDate = moment(rainGGPrevResponse.data.startDate).format(
          "MMMM DD, YYYY"
        );
        this.rainGGPrevEndDate = moment(rainGGPrevResponse.data.endDate)
          .subtract(1, "days")
          .format("MMMM DD, YYYY");

        this.startRainGGTimer();
      } catch (error) {
        console.error("Error loading RainGG leaderboard data", error);
      }
    },

    async fetchUpgraderData() {
      const baseUrl = process.env.VUE_APP_BASE_API;

      try {
        const upgraderResponse = await axios.get(`${baseUrl}/api/leaderboard/upgrader`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        });

        const upgraderPrevResponse = await axios.get(
          `${baseUrl}/api/leaderboard/upgrader?previous=true`,
          { headers: { "x-api-key": process.env.VUE_APP_X_API_KEY } }
        );

        // console.log(upgraderResponse.data, "upgrader data");

        // Update Upgrader data after it's loaded
        this.upgraderLeaderboard = upgraderResponse.data.leaderboard || [];
        this.upgraderPrevLeaderboard = upgraderPrevResponse.data.leaderboard || [];
        this.upgraderNotFormattedEndDate = upgraderResponse.data.endDate || null;
        this.upgraderStartDate = moment(upgraderResponse.data.startDate).format(
          "MMMM DD, YYYY"
        );
        this.upgraderEndDate = moment(upgraderResponse.data.endDate)
          .subtract(1, "days")
          .format("MMMM DD, YYYY");
        this.upgraderPrevStartDate = moment(upgraderPrevResponse.data.startDate).format(
          "MMMM DD, YYYY"
        );
        this.upgraderPrevEndDate = moment(upgraderPrevResponse.data.endDate)
          .subtract(1, "days")
          .format("MMMM DD, YYYY");

        // console.log("Upgrader Start Date:", this.upgraderStartDate);
        // console.log("Upgrader End Date:", this.upgraderEndDate);

        // console.log(this.upgraderNotFormattedEndDate);

        this.startUpgraderTimer();
      } catch (error) {
        console.error("Error loading Upgrader leaderboard data", error);
      }
    },
    changeLeaderboard() {
      if (this.currentLeaderboard === "roobet") {
        this.topRest = this.roobetLeaderboard.slice(0, 10);
      } else if (this.currentLeaderboard === "raingg") {
        this.topRest = this.rainGGLeaderboard.slice(0, 10);
      } else if (this.currentLeaderboard === "upgrader") {
        this.topRest = this.upgraderLeaderboard.slice(0, 10);
      }

      if (this.previousLeaderboard === "roobet") {
        this.topRestPrev = this.roobetPrevLeaderboard.slice(0, 10);
      } else if (this.previousLeaderboard === "raingg") {
        this.topRestPrev = this.rainGGPrevLeaderboard.slice(0, 10);
      } else if (this.previousLeaderboard === "upgrader") {
        this.topRestPrev = this.upgraderPrevLeaderboard.slice(0, 10);
      }

      // console.log("Current Leaderboard:", this.currentLeaderboard);
      // console.log("Top Rest Data:", this.topRest);
    },

    toggleLeaderboard(leaderboardType) {
      this.currentLeaderboard = leaderboardType;
      this.previousLeaderboard = leaderboardType;
      this.changeLeaderboard();
    },

    toggleDisclaimer() {
      this.showDisclaimer = !this.showDisclaimer;
    },

    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    getCurrentRewardValue(rank) {
      let reward = "";

      if (this.currentLeaderboard === "roobet") {
        reward = "$" + this.getFormattedReward(rank, this.roobetCurrentPrizes);
      } else if (this.currentLeaderboard === "raingg") {
        reward = this.getFormattedReward(rank, this.rainGGCurrentPrizes) + "c";
      } else if (this.currentLeaderboard === "upgrader") {
        reward = "$" + this.getFormattedReward(rank, this.upgraderCurrentPrizes);
      }
      return reward;
    },

    getPrevRewardValue(rank) {
      let reward = "";

      if (this.previousLeaderboard === "roobet") {
        reward = "$" + this.getFormattedReward(rank, this.roobetPrevPrizes);
      } else if (this.previousLeaderboard === "raingg") {
        reward = this.getFormattedReward(rank, this.rainGGPrevPrizes) + "c";
      } else if (this.previousLeaderboard === "upgrader") {
        reward = "$" + this.getFormattedReward(rank, this.upgraderPrevPrizes);
      }

      return reward;
    },

    getFormattedReward(rank, prizes) {
      if (!prizes || !Array.isArray(prizes) || rank < 1 || rank > prizes.length) {
        return ""; // Default if rank is out of range or prizes are invalid
      }
      return `${prizes[rank - 1]}`;
    },

    maskString(str) {
      if (!str || str.length <= 4) return str;
      const start = str.slice(0, 2);
      const end = str.slice(-2);
      const middleAsterisks = "*".repeat(str.length - 4);
      return `${start}${middleAsterisks}${end}`;
    },

    startRoobetTimer() {
      const getNextTargetDate = () => {
        if (!this.roobetNotFormattedEndDate) {
          console.error("No Roobet End Date Available");
          return null;
        }

        const now = moment().utc();
        let targetDate = moment
          .utc(this.roobetNotFormattedEndDate)
          .subtract(1, "seconds");

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "month");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      if (!targetDate) return;

      let timerInterval;

      const updateTimer = () => {
        const nowUtc = moment().utc();
        const distance = targetDate.diff(nowUtc);

        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.roobetTimer = "0d 0h 0m 0s";
          clearInterval(timerInterval);
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },

    startRainGGTimer() {
      const getNextTargetDate = () => {
        if (!this.rainGGNotFormattedEndDate) return null;

        const now = moment().utc();
        let targetDate = moment
          .utc(this.rainGGNotFormattedEndDate)
          .subtract(1, "seconds");

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "month");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      if (!targetDate) return;

      let timerInterval;

      const updateTimer = () => {
        const nowEst = moment().tz("America/New_York");
        const distance = targetDate.diff(nowEst);

        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.rainGGTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.rainGGTimer = "0d 0h 0m 0s";
          clearInterval(timerInterval);
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },

    startUpgraderTimer() {
      const getNextTargetDate = () => {
        if (!this.upgraderNotFormattedEndDate) return null;

        const now = moment().utc();
        let targetDate = moment
          .utc(this.upgraderNotFormattedEndDate)
          .subtract(1, "seconds");

        if (now.isAfter(targetDate)) {
          targetDate.add(2, "weeks");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      if (!targetDate) return;

      let timerInterval;

      const updateTimer = () => {
        const nowEst = moment().tz("America/New_York");
        const distance = targetDate.diff(nowEst);

        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.upgraderTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.upgraderTimer = "0d 0h 0m 0s";
          clearInterval(timerInterval);
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },
  },

  mounted() {
    this.init();
    this.fetchRainGGData();
    this.fetchUpgraderData();
  },
};
</script>

<style scoped>
/* Smooth fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.timer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px; /* Reduced width for mobile */
  height: 90px; /* Reduced height for mobile */
  background-color: #20122e;
  border-radius: 10px;
  border: 2px solid #411d35;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: center;
}

.timer-box h4 {
  font-size: 36px; /* Reduced font size for mobile */
  color: white;
  font-weight: bold;
}

.timer-box h6 {
  font-size: 14px; /* Adjusted for better mobile readability */
  color: white;
  text-transform: uppercase;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .timer-box {
    width: 90px;
    height: 90px;
  }

  .timer-box h4 {
    font-size: 36px;
  }

  .timer-box h6 {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .timer-box {
    width: 60px; /* Further reduced for very small screens */
    height: 60px;
  }

  .timer-box h4 {
    font-size: 20px;
  }

  .timer-box h6 {
    font-size: 10px;
  }
}

/* Ensure the timer boxes wrap correctly */
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px; /* Space between timer boxes */
}

.border-gradient {
  position: relative;
  border-radius: 8px;
}

.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(90deg, #d19e00 0%, #c775df 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gold-glow {
  text-shadow: 0px 0px 4px #ffd700;
}
</style>
