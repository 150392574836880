<template>
    <div
      v-if="showErrorModal"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50"
    >
      <div
        class="relative w-[420px] sm:w-[500px] bg-[#20122E] py-8 px-6 rounded-2xl text-center flex flex-col items-center overflow-hidden"
      >
        <!-- Close Button -->
        <button
          @click="$emit('closeErrorModal')"
          class="absolute top-4 right-6 text-white text-4xl font-bold hover:text-gray-300 transition"
        >
          &times;
        </button>
  
        <div class="mt-10">
          <h2 class="text-red-500 text-2xl font-black drop-shadow-md">
            Oops! Something Went Wrong
          </h2>
          <p class="text-white text-sm px-4 leading-relaxed mt-4">
            {{ errorMessage }}
          </p>
  
          <button
            @click="$emit('closeErrorModal')"
            class="mt-6 px-6 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-lg hover:bg-red-400 transition"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showErrorModal: Boolean,
      errorMessage: String,
    },
  };
  </script>
  