<template>
  <!-- Main section start -->
  <section class="relative h-screen lg:h-screen main-bg">
    <!-- OVERLAYS -->

    <img
      src="@/assets/overlay-1.webp"
      class="hidden lg:flex absolute top-[660px] -left-[160px] lg:-left-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 1"
    />

    <img
      src="@/assets/overlay-2.webp"
      class="hidden lg:flex absolute top-[1000px] right-[260px] lg:-right-[100px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 2"
    />

    <img
      src="@/assets/overlay-3.webp"
      class="hidden lg:flex absolute top-[1540px] left-[260px] lg:-left-[150px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 3"
    />

    <img
      src="@/assets/overlay-4.webp"
      class="hidden lg:flex absolute top-[1820px] lg:-right-[130px] z-20 object-contain w-[340px] h-[340px]"
      alt="overlay 4"
    />

    <div
      class="flex flex-col p-8 pt-14 md:pt-20 lg:pt-20 2xl:pt-44 lg:px-16 2xl:px-56 overflow-hidden"
    >
      <div class="flex justify-center mt-16">
        <a href="https://roobet.com/?ref=mik" target="_blank">
          <img
            v-motion-pop-visible
            src="../assets/gambagold-logo.webp"
            alt="title-text"
            class="w-[260px] md:w-[320px] lg:w-[400px]"
          />
        </a>
      </div>
      <div class="flex flex-col items-center mt-6 text-center">
        <h2
          v-motion-pop-visible
          class="text-[26px] lg:text-[34px] font-bold text-white font-montserrat"
        >
          Stay Connected, Engage & Earn!
        </h2>
        <div
    class="flex flex-col items-center justify-center gap-4 mt-4 lg:flex-row w-full max-w-[420px] lg:max-w-full lg:w-[600px]"
  >
    <a
      v-motion-pop-visible
      href="/leaderboards"
      class="px-6 py-7 text-sm font-bold w-full rounded-lg shadow-lg font-montserrat transition-all duration-300 border-2 border-[#FFD703]"
      :class="isHovered ? 'bg-transparent text-[#FFD703]' : 'bg-[#FFD703] text-black'"
      @mouseenter="toggleHover(true)"
      @mouseleave="toggleHover(false)"
    >
      VIEW LEADERBOARDS
    </a>
    <a
      v-motion-pop-visible
      href="https://kick.com/Mikhailis"
      target="_blank"
      class="px-6 py-3 font-bold w-full rounded-lg shadow-lg font-montserrat transition-all duration-300 border-2 border-[#FFD703]"
      :class="isHovered ? 'bg-[#FFD703] text-black' : 'bg-transparent text-[#FFD703]'"
      @mouseenter="toggleHover(true)"
      @mouseleave="toggleHover(false)"
    >
      WATCH STREAM AT KICK.COM
    </a>
  </div>
        <div
          class="flex items-center justify-center w-full gap-6 mt-6 font-montserrat"
        >
          <div
            v-motion-pop-visible
            class="p-4 text-center w-[200px] hover:cursor-pointer lg:w-[284px] bg-[#20122E] rounded-lg"
            @click="redirectToRoobetReferral"
          >
            <p class="text-[#7F85A3] font-bold">Play on</p>
            <img src="@/assets/roobet.webp" alt="Roobet" class="mx-auto mt-2" />
          </div>
          <div
            v-motion-pop-visible
            class="p-4 text-center w-[200px] hover:cursor-pointer lg:w-[284px] bg-[#20122E] rounded-lg"
            @click="redirectToRainGGReferral"
          >
            <p class="text-[#7F85A3] font-bold">Play on</p>
            <img
              src="@/assets/raingg.webp"
              alt="RainGG"
              class="mx-auto w-[200px] mt-2"
            />
          </div>
          <div
            v-motion-pop-visible
            class="p-4 text-center w-[200px] hover:cursor-pointer lg:w-[284px] bg-[#20122E] rounded-lg"
            @click="redirectToUpgraderReferral"
          >
            <p class="text-[#7F85A3] font-bold">Play on</p>
            <img
              src="@/assets/upgrader.webp"
              alt="Upgrader"
              class="mx-auto w-[200px] mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Socials  -->
  <section
    class="relative overflow-hidden px-10 pt-[120px] pb-[121px] md:pt-[120px] md:pb-[158px] lg:pb-[130px] bg-gradient-to-b from-[#0A0D1D] to-[#0A0D1D]"
  >
    <h1
      v-motion-pop-visible
      class="text-[32px] md:text-xl lg:text-2xl text-white leading-10 text-center font-montserrat"
    >
      FOLLOW MY SOCIALS
    </h1>

    <div
      class="flex flex-col sm:flex-row justify-center items-center gap-y-10 sm:gap-x-10 mt-[80px] sm:mt-[70px] md:mt-[100px] lg:mt-[60px]"
    >
      <!-- Kick Button -->
      <a
        v-motion-pop-visible
        href="https://kick.com/Mikhailis"
        target="_blank"
        class="hover:cursor-pointer"
      >
        <img
          src="../assets/kick.webp"
          class="object-contain w-16 h-16 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20"
          alt="kick"
        />
      </a>

      <!-- YouTube Button -->
      <a
        v-motion-pop-visible
        href="https://www.youtube.com/@GambaGolds"
        target="_blank"
        class="hover:cursor-pointer"
      >
        <img
          src="../assets/youtube.webp"
          class="object-contain w-16 h-16 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20"
          alt="youtube"
        />
      </a>

      <!-- Instagram Button -->
      <a
        v-motion-pop-visible
        href="#"
        target="_blank"
        class="hover:cursor-pointer"
      >
        <img
          src="../assets/insta.webp"
          class="object-contain w-16 h-16 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20"
          alt="instagram"
        />
      </a>

      <!-- X Button -->
      <a
        v-motion-pop-visible
        href="https://twitter.com/MikhailisGG"
        target="_blank"
        class="hover:cursor-pointer"
      >
        <img
          src="../assets/twitter.webp"
          class="object-contain w-16 h-16 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20"
          alt="X"
        />
      </a>

      <!-- Discord Button -->
      <a
        v-motion-pop-visible
        href="https://discord.com/invite/gambagold"
        target="_blank"
        class="hover:cursor-pointer"
      >
        <img
          src="../assets/discord.webp"
          class="object-contain w-16 h-16 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20"
          alt="discord"
        />
      </a>
    </div>
  </section>

  <section
    id="rewards"
    class="px-4 sm:px-6 py-20 lg:px-16 2xl:px-56 bg-gradient-to-b from-[#0A0D1D] to-[#0A0D1D]"
  >
    <div class="flex flex-col items-center">
      <div v-motion-pop-visible class="relative w-full max-w-6xl">
        <img
          src="../assets/rewards-banner.webp"
          alt="Rewards Banner"
          class="w-full"
        />
      </div>
      <div
        class="grid w-full max-w-6xl grid-cols-1 gap-6 mt-8 md:grid-cols-2 lg:grid-cols-3"
      >
        <!-- Roobet Reward Card -->
        <div
          v-motion-pop-visible
          class="flex flex-col justify-start items-start bg-[#20122E] rounded-lg p-6 text-white text-center"
        >
          <img src="../assets/roobet.webp" alt="Roobet" class="w-[200px] mb-4" />
          <h3 class="text-lg font-black text-white font-montserrat">
            $5,000 Monthly
          </h3>
          <p class="text-md text-[#7F85A3] mt-2">Leaderboard</p>
          <button
              @click="redirectToRoobetReferral"
            class="mt-4 bg-[#FFD703] text-black font-bold font-montserrat py-2 px-4 rounded w-full flex items-center justify-center gap-2"
          >
            <img src="../assets/gift-outline.webp" alt="Gift" class="w-5 h-5" />
            Claim Reward
          </button>
          <ul
            class="flex flex-col items-start justify-start mt-10 space-y-1 lg:text-xs xl:text-base font-montserrat"
          >
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Click the Claim Reward Button
            </li>
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Sign up under code "MIK"
            </li>
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Enjoy your Rewards!
            </li>
          </ul>
          <div
            class="flex items-center hover:cursor-pointer justify-between w-full gap-2 font-bold text-[#7F85A3] text-md mt-10"
            @click="redirectToRoobetReferral"
          >
            Sign up and Use Code
            <img
              src="../assets/copy.webp"
              alt="Copy"
              class="w-5 h-5 cursor-pointer"
            />
          </div>
          <div
            @click="redirectToRoobetReferral"
            class="text-lg font-black text-white hover:cursor-pointer"
          >
            MIK
          </div>
        </div>
        <!-- Upgrader Reward Card -->
        <div
          v-motion-pop-visible
          class="flex flex-col justify-start items-start bg-[#20122E] rounded-lg p-6 text-white text-center"
        >
          <img
            src="../assets/raingg.webp"
            alt="Upgrader"
            class="w-[200px] mb-4"
          />
          <h3 class="text-lg font-black text-white font-montserrat">
            5,000c Monthly
          </h3>
          <p class="text-md text-[#7F85A3] mt-2">Leaderboard</p>
          <button
              @click="redirectToRainGGReferral"
            class="mt-4 bg-[#FFD703] text-black font-bold font-montserrat py-2 px-4 rounded w-full flex items-center justify-center gap-2"
          >
            <img src="../assets/gift-outline.webp" alt="Gift" class="w-5 h-5" />
            Claim Reward
          </button>
          <ul
            class="flex flex-col items-start justify-start mt-10 space-y-1 lg:text-xs xl:text-base font-montserrat"
          >
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Click the Claim Reward Button
            </li>
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Sign up under code "MIK"
            </li>
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Enjoy your Rewards!
            </li>
          </ul>
          <div
            class="flex items-center justify-between w-full hover:cursor-pointer gap-2 font-bold text-[#7F85A3] text-md mt-10"
            @click="redirectToRainGGReferral"
          >
            Sign up and Use Code
            <img
              src="../assets/copy.webp"
              alt="Copy"
              class="w-5 h-5 cursor-pointer"
            />
          </div>
          <div
            @click="redirectToRainGGReferral"
            class="text-lg font-black text-white hover:cursor-pointer"
          >
            MIK
          </div>
        </div>

            <!-- Upgrader Reward Card -->
            <div
          v-motion-pop-visible
          class="flex flex-col justify-start items-start bg-[#20122E] rounded-lg p-6 text-white text-center"
        >
          <img
            src="../assets/upgrader.webp"
            alt="Upgrader"
            class="w-[200px] mb-4"
          />
          <h3 class="text-lg font-black text-white font-montserrat">
            $1,250 Bi-Weekly
          </h3>
          <p class="text-md text-[#7F85A3] mt-2">Leaderboard</p>
          <button
              @click="redirectToUpgraderReferral"
            class="mt-4 bg-[#FFD703] text-black font-bold font-montserrat py-2 px-4 rounded w-full flex items-center justify-center gap-2"
          >
            <img src="../assets/gift-outline.webp" alt="Gift" class="w-5 h-5" />
            Claim Reward
          </button>
          <ul
            class="flex flex-col items-start justify-start mt-10 space-y-1 lg:text-xs xl:text-base font-montserrat"
          >
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Click the Claim Reward Button
            </li>
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Sign up under code "MIK"
            </li>
            <li class="flex items-center gap-2 text-[#7F85A3] font-medium">
              <img src="../assets/gift-white.webp" alt="Gift" class="w-6 h-6" />
              Enjoy your Rewards!
            </li>
          </ul>
          <div
            class="flex items-center justify-between w-full hover:cursor-pointer gap-2 font-bold text-[#7F85A3] text-md mt-10"
            @click="redirectToUpgraderReferral"
          >
            Sign up and Use Code
            <img
              src="../assets/copy.webp"
              alt="Copy"
              class="w-5 h-5 cursor-pointer"
            />
          </div>
          <div
            @click="redirectToUpgraderReferral"
            class="text-lg font-black text-white hover:cursor-pointer"
          >
            MIK
          </div>
        </div>

        <!-- More Promos Card -->
        <!-- <div
          v-motion-pop-visible
          class="bg-[#20122E] rounded-lg p-6 text-white text-center flex flex-col justify-center items-center"
        >
          <img
            src="../assets/gambagold-logo.webp"
            alt="More Promos"
            class="w-32 mx-auto mb-4"
          />
          <h3 class="text-lg font-black text-white font-montserrat">
            More Promos to Come!
          </h3>
          <p class="text-sm text-[#7F85A3] font-semibold font-montserrat">
            Stay Tuned
          </p>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
// import RoobetLeaderboardSection from "@/sections/RoobetLeaderboardSection.vue";
// import UpgraderLeaderboardSection from "@/sections/UpgraderLeaderboardSection.vue";

export default defineComponent({
  components: {
    // RoobetLeaderboardSection,
    // UpgraderLeaderboardSection,
  },
  data() {
    return {
      loading: false,
      selectedLeaderboard: 1,
      isHovered: false,
    };
  },
  methods: {
    redirectToRoobetReferral() {
      window.location.href = "https://roobet.com/?ref=mik";
    },
    redirectToRainGGReferral() {
      window.location.href = "https://rain.gg/r/Mik";
    },
    redirectToUpgraderReferral() {
      window.location.href = "https://upgrader.com/r/MIK";
    },
    toggleHover(state) {
      this.isHovered = state; // ✅ Updates hover state dynamically
    },
  },
});
</script>

<style scope>
body {
  background-color: #0b0b0c;
}
.main-bg {
  background-image: url("../assets/hero-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

</style>
