<template>
  <div
    v-if="show"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-4"
  >
    <div
      class="relative w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl bg-[#261B3C] py-6 px-5 rounded-2xl text-center flex flex-col items-center shadow-lg"
    >
      <!-- Close Button -->
      <button
        @click="$emit('close')"
        class="absolute top-4 right-6 text-white text-3xl font-bold hover:text-gray-300 transition duration-200"
      >
        &times;
      </button>

      <!-- Title -->
      <h2 class="text-[#FACC15] text-xl sm:text-2xl font-bold drop-shadow-md mb-4">
        Transaction History
      </h2>

      <!-- Transactions List -->
      <div
        v-if="transactions.length > 0"
        class="w-full max-h-72 overflow-y-auto overflow-hidden space-y-3 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 px-1"
      >
        <div
          v-for="transaction in transactions"
          :key="transaction._id"
          class="bg-[#322650] text-white p-4 rounded-lg shadow-md border border-gray-700"
        >
          <div class="flex justify-between text-sm">
            <span class="text-yellow-400 font-bold">Product:</span>
            <span class="text-right text-white">{{ transaction.product.title }}</span>
          </div>
          <div class="flex justify-between text-sm mt-2">
            <span class="text-yellow-400 font-bold">Amount:</span>
            <span class="text-right text-white">
              {{ transaction.amount }}
              {{ transaction.amount > 1 ? "points" : "point" }}</span
            >
          </div>
          <div class="flex justify-between text-sm mt-2">
            <span class="text-yellow-400 font-bold mt-2">Status:</span>
            <span
              :class="statusClass(transaction.status)"
              class="text-right"
            >
              {{ formatStatus(transaction.status) }}
            </span>
          </div>
          <p class="text-xs text-white text-right mt-1 font-bold">
            {{ new Date(transaction.createdAt).toLocaleString() }}
          </p>
        </div>
      </div>

      <!-- Empty State -->
      <p v-else class="text-white text-sm mt-4">No transactions found.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    transactions: Array,
  },
  methods: {
    formatStatus(status) {
      const statusMap = {
        completed: "Completed",
        pending: "Pending",
        inprogress: "In Progress",
        declined: "Declined",
        cancelled: "Cancelled",
        refunded: "Refunded",
      };
      return statusMap[status] || "Unknown";
    },
    statusClass(status) {
      return {
        "text-green-400": status === "completed",
        "text-yellow-400": status === "pending" || status === "inprogress",
        "text-red-400": status === "declined" || status === "cancelled",
        "text-blue-400": status === "refunded",
      };
    },
  },
};
</script>

<style>
/* Custom Scrollbar for better UI */
.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}
.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
}
</style>
